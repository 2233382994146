































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import {
  MyjbiGroupDetail,
  MyjbiGroupUserAttributeSpec
} from '../../../jbi-shared/types/myjbi-group.types';
import CsvImportGuide from '@/components/CsvImportGuide.vue';
import GroupSettingsMembersSection from '@/views/AdminCreateGroup/components/GroupSettingsMembersSection.vue';
import { MemberObject } from '../../../utils/group.util';
import { ValidationObserver } from 'vee-validate';
import { RootState } from '../../../store/store';
import { isTruthy } from '../../../jbi-shared/util/watcher.vue-decorator';
import { UserAttributesStringInputOption } from '../../../store/modules/admin/types/group.types';

@Component({
  components: {
    CsvImportGuide,
    GroupSettingsMembersSection,
    ValidationObserver
  }
})
export default class AddMultipleMembersModal extends Vue {
  @Prop(Array) attributeSpecs!: MyjbiGroupDetail['groupUserAttributeSpecs'];
  @Prop() allowedEmailDomains!: string[];

  membersFile: File | null = null;
  membersData: MemberObject[] | null = null;
  notify = false;
  isGroupValid = false;

  get userAttributes(): MyjbiGroupUserAttributeSpec[] {
    return this.attributeSpecs ? this.attributeSpecs : [];
  }

  get isLoading() {
    return (this.$store.state as RootState).admin.apiState.addMembersToGroup
      .loading;
  }

  get isSucceeded() {
    return (this.$store.state as RootState).admin.apiState.addMembersToGroup
      .success;
  }

  public isGroupMemberValid(members: MemberObject[] | null) {
    if (!members) {
      return;
    }
    const checkMemberListIsValid = members.filter((user) => {
      const invalidAttribute = Object.entries(user)
        .map((attribute) => attribute[1].isValid)
        .filter((attribute) => !!attribute === false);

      if (
        invalidAttribute.findIndex((attribute) => !!attribute === false) !== -1
      ) {
        return invalidAttribute;
      }
    });
    this.isGroupValid = checkMemberListIsValid?.length > 0 ? false : true;
  }

  @Watch('membersData', { deep: true })
  public watchMembersData() {
    this.isGroupMemberValid(this.membersData);
  }

  @Watch('isSucceeded')
  @isTruthy
  onSucceeded() {
    // @ts-ignore
    this.$parent.close();
  }
}
