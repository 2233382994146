var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseTable',{class:_vm.$style.container,scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('tr',[_c('th'),_vm._l((_vm.filteredAttributes),function(attribute,attributeIndex){return _c('th',{key:("attribute_" + attributeIndex)},[_vm._v(" "+_vm._s(attribute.groupUserAttribute.name)+" ")])})],2)]},proxy:true},{key:"body",fn:function(){return [(_vm.getUsersSuccess)?_vm._l((_vm.tempItems),function(user,index){return _c('tr',{key:index},[_c('td',[_c('b-checkbox',{attrs:{"native-value":user},model:{value:(_vm.selectedItems),callback:function ($$v) {_vm.selectedItems=$$v},expression:"selectedItems"}})],1),_vm._l((_vm.filteredAttributes),function(attribute){return _c('td',{key:attribute.groupUserAttribute.id},[(attribute.groupUserAttribute.slug === 'first-name')?[(_vm.items[index].firstName)?_c('div',[_vm._v(" "+_vm._s(_vm.items[index].firstName)+" ")]):_c('div',[_c('b-field',{class:_vm.$style.inputField,attrs:{"type":!_vm.isValid(user, attribute).isValid ? 'is-danger' : '',"message":_vm.isValid(user, attribute).errorMessage}},[_c('b-input',{attrs:{"type":"text"},model:{value:(user.firstName),callback:function ($$v) {_vm.$set(user, "firstName", $$v)},expression:"user.firstName"}})],1)],1)]:(attribute.groupUserAttribute.slug === 'last-name')?[(_vm.items[index].lastName)?_c('div',[_vm._v(" "+_vm._s(_vm.items[index].lastName)+" ")]):_c('div',[_c('b-field',{class:_vm.$style.inputField,attrs:{"type":!_vm.isValid(user, attribute).isValid ? 'is-danger' : '',"message":_vm.isValid(user, attribute).errorMessage}},[_c('b-input',{attrs:{"type":"text"},model:{value:(user.lastName),callback:function ($$v) {_vm.$set(user, "lastName", $$v)},expression:"user.lastName"}})],1)],1)]:(attribute.groupUserAttribute.slug === 'email')?[_c('div',[_vm._v(" "+_vm._s(user.email || '-')+" ")])]:[(
                  attribute.groupUserAttribute.groupUserAttributeType.type ===
                  'date'
                )?_c('div',[_c('b-field',{class:_vm.$style.inputField,attrs:{"type":!_vm.isValid(user, attribute).isValid ? 'is-danger' : '',"message":_vm.isValid(user, attribute).errorMessage}},[(
                      attribute.groupUserAttribute.groupUserAttributeType
                        .type === 'date'
                    )?_c('b-datepicker',{attrs:{"icon-pack":"mdi","icon":"calendar","trap-focus":""},on:{"input":function($event){return _vm.isValid(user, attribute)}},model:{value:(user[attribute.groupUserAttribute.slug]),callback:function ($$v) {_vm.$set(user, attribute.groupUserAttribute.slug, (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"user[attribute.groupUserAttribute.slug]"}},[_c('b-button',{class:_vm.$style.calendarCancelButton,attrs:{"label":"Clear"},on:{"click":function($event){user[attribute.groupUserAttribute.slug] = null}}})],1):_vm._e()],1)],1):(
                  attribute.groupUserAttribute.groupUserAttributeType.type ===
                  'image'
                )?_c('div',[_c('b-field',{class:_vm.$style.inputField},[_c('ImageUploadComponent',{attrs:{"imageSrc":user[attribute.groupUserAttribute.slug]},on:{"getImage":function (imageUrl) { return _vm.getImage(imageUrl, index, attribute); }}})],1)],1):(
                  attribute.groupUserAttribute.groupUserAttributeType.type ===
                  'list'
                )?_c('div',[_c('b-field',{key:_vm.listRenderKey,class:_vm.$style.inputField,attrs:{"type":!_vm.isValid(user, attribute).isValid ? 'is-danger' : ''}},[_c('ListDropdownComponent',{attrs:{"memberAttributeValue":user[attribute.groupUserAttribute.slug],"groupUserAttributeSpec":attribute,"isValid":_vm.isValid(user, attribute).isValid,"errorMessage":_vm.isValid(user, attribute).errorMessage},on:{"getListValue":function (selectedListValues) { return _vm.getListValue(selectedListValues, index, attribute); }}})],1)],1):(
                  attribute.groupUserAttribute.groupUserAttributeType.type ===
                  'text area'
                )?_c('div',[_c('b-field',{key:_vm.textEditorRenderKey,class:_vm.$style.inputField,attrs:{"type":!_vm.isValid(user, attribute).isValid ? 'is-danger' : '',"message":_vm.isValid(user, attribute).errorMessage}},[_c('b-input',{class:_vm.$style.textAreaTrigger,attrs:{"icon-right":"pencil","value":_vm.renderTextAreaContent(
                        user[attribute.groupUserAttribute.slug]
                      )},nativeOn:{"click":function($event){return _vm.openRichTextEditor(
                        index,
                        attribute.required,
                        attribute.groupUserAttribute.name,
                        attribute.groupUserAttribute.slug
                      )}}})],1)],1):_c('div',[_c('b-field',{class:_vm.$style.inputField,attrs:{"type":!_vm.isValid(user, attribute).isValid ? 'is-danger' : '',"message":_vm.isValid(user, attribute).errorMessage}},[_c('b-input',{attrs:{"type":"text"},model:{value:(user[attribute.groupUserAttribute.slug]),callback:function ($$v) {_vm.$set(user, attribute.groupUserAttribute.slug, $$v)},expression:"user[attribute.groupUserAttribute.slug]"}})],1)],1)]],2)})],2)}):_c('tr',[_c('td',{staticClass:"is-paddingless",attrs:{"colspan":"100%"}},[_c('BaseLoading')],1)])]},proxy:true},{key:"footer",fn:function(){return [_c('tr',[_c('td',{staticClass:"is-paddingless",attrs:{"colspan":"100%"}},[(_vm.items && _vm.items.length > 0)?_c('BasePagination',{attrs:{"page":_vm.page,"perPage":_vm.perPage,"totalNumberOfPage":_vm.totalNumberOfPage,"totalCount":_vm.totalCount,"isFirstPage":_vm.isFirstPage,"isLastPage":_vm.isLastPage,"startItemIndex":_vm.startItemIndex,"endItemIndex":_vm.endItemIndex},on:{"update:page":function($event){_vm.page=$event},"update:perPage":function($event){_vm.perPage=$event},"update:per-page":function($event){_vm.perPage=$event}}}):_c('p',{staticClass:"p-2 has-text-centered"},[_vm._v("No user found")])],1)])]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }