



















































































































































































































import {
  Component,
  Vue,
  Prop,
  Watch,
  Model,
  Emit
} from 'vue-property-decorator';
import BasePagination from '@/components/base/BasePagination.vue';
import BaseLoading from '@/components/base/BaseLoading.vue';
import { mixins } from 'vue-class-component';
import { PaginationMixin } from '@/components/base/pagination.mixin';
import {
  ToastProgrammatic as Toast,
  ToastProgrammatic,
  DialogProgrammatic as Dialog
} from 'buefy';
import dayjs from 'dayjs';
import BaseTable from '@/components/BaseTable.vue';
import {
  MyjbiGroupDetail,
  MyjbiGroupMember
} from '../../../jbi-shared/types/myjbi-group.types';
import TableActionDropdown from '@/components/TableActionDropdown.vue';
import { MemberObject } from '../../../utils/group.util';
import SingleMemberModal from '@/views/AdminViewGroup/components/SingleMemberModal.vue';
import { RootState } from '../../../store/store';
import { StringInputOption } from '../../../jbi-shared/types/form.types';
import { cloneDeep } from 'lodash';
import DropdownMultiselect from '@/components/DropdownMultiselect.vue';
import { Action, State } from 'vuex-class';
import { ResendEmailNotificationToGroupMemberPayload } from '../../../store/modules/admin/types/group.types';
import delay from 'delay';
import { uniq as _uniq, orderBy as _orderBy } from 'lodash';
import SortableTableHeader from '@/components/SortableTableHeader.vue';
import { GroupAttributeOrder } from '../../../store/modules/admin/types/admin.types';
import { BaseAttributeSlugs } from '@/store/modules/profile/profile.state';
import { getHtmlContent } from '@/jbi-shared/util/group-level-attributes.util';
import {
  PermissionsMatrixActionsEnum,
  ResourceExceptions
} from '../../../store/modules/roles-and-permissions/types/roles-and-permissions.types';
import { isUserAllowed } from '../../../utils/rbac.util';
import { EntityTypes } from '../../../store/modules/module-tree/enums/module-tree.enums';

@Component({
  computed: {
    PermissionsMatrixActionsEnum() {
      return PermissionsMatrixActionsEnum;
    }
  },
  components: {
    BasePagination,
    SortableTableHeader,
    BaseTable,
    BaseLoading,
    TableActionDropdown,
    DropdownMultiselect
  }
})
export default class MemberList extends mixins(PaginationMixin) {
  @Prop() public items!: MyjbiGroupDetail['members'];
  @Prop(Number) public currentPage!: number;
  @Prop(Number) public totalNumberOfPage!: number;
  @Prop(Number) public totalCount!: number;
  @Prop(Boolean) public isFirstPage!: boolean;
  @Prop(Boolean) public isLastPage!: boolean;
  @Prop(Number) public startItemIndex!: number;
  @Prop(Number) public endItemIndex!: number;
  @Prop() attributeSpecs!: MyjbiGroupDetail['groupUserAttributeSpecs'];
  @Prop(String) public sortColumn!: string;
  @Prop(String) public sortOrder!: 'ASC' | 'DESC';
  @Prop() allowedEmailDomains!: string[];
  @Prop() groupTypeName!: string;
  @Prop() groupExceptions!: ResourceExceptions;

  @Action('admin/upsertGroupAttributeOrder')
  upsertGroupAttributeOrder!: (
    params: GroupAttributeOrder
  ) => Promise<GroupAttributeOrder>;

  @Action('admin/resendEmailNotificationToGroupMember')
  resendEmailNotificationToGroupMember!: (
    id: ResendEmailNotificationToGroupMemberPayload
  ) => Promise<void>;

  selectedAttrs: StringInputOption[] = [];

  @State((state: RootState) => state.admin.apiState.getMembers.success)
  public getMembersSuccess!: boolean;

  convertTime(time: string) {
    return dayjs(time).format('DD MMM YYYY');
  }

  public isUserAllowed(
    action: PermissionsMatrixActionsEnum,
    module: string,
    skipImplicitCheck?: boolean
  ): boolean {
    const instance = EntityTypes.GROUP + '_' + this.groupId;
    return isUserAllowed(
      action,
      module,
      EntityTypes.GROUP,
      this.groupTypeName,
      this.groupId,
      this.groupExceptions,
      skipImplicitCheck
    );
  }

  get groupName() {
    return (this.$store.state as RootState)?.admin?.groupDetail?.name;
  }

  get groupId() {
    return (this.$store.state as RootState)?.admin?.groupDetail?.id;
  }

  get attributeOrder() {
    return (this.$store.state as RootState).admin.groupAttributeOrder;
  }

  get attributes(): StringInputOption[] {
    return this.attributeSpecs
      ? this.attributeSpecs.map((spec) => ({
          id: spec.groupUserAttribute.slug,
          name: spec.groupUserAttribute.name
        }))
      : [];
  }

  get selectedAttributeSpecs() {
    return this.attributeSpecs?.filter((attribute) => {
      const { slug } = attribute.groupUserAttribute;
      return this.selectedAttrs.map((a) => a.id).includes(slug);
    });
  }

  renderTextAreaContent(attributeValue: string): string {
    return getHtmlContent(attributeValue || '');
  }

  public formatedDate(date: string) {
    return dayjs(date).format('DD MMM YYYY');
  }

  public onClickHeaderColumn(columnName: string) {
    if (this.sortColumn === columnName) {
      return this.$emit('sort', {
        sortColumn: columnName,
        sortOrder: this.sortOrder === 'ASC' ? 'DESC' : 'ASC'
      });
    }
    this.$emit('sort', {
      sortColumn: columnName,
      sortOrder: 'DESC'
    });
  }

  public getSortOrderOfColumn(name: any) {
    if (this.sortColumn === name) {
      return this.sortOrder;
    }

    return 'ASC';
  }

  handleEditMember(member: MyjbiGroupMember) {
    const { userId } = member;
    const defaultMemberAttributes = {
      email: member.attributes.email,
      firstName: member.attributes['first-name'],
      lastName: member.attributes['last-name']
    };

    this.$buefy.modal.open({
      parent: this,
      component: SingleMemberModal,
      hasModalCard: true,
      trapFocus: true,
      canCancel: false,
      props: {
        groupId: this.groupId,
        groupTypeName: this.groupTypeName,
        attributeSpecs: this.attributeSpecs,
        member,
        isEdit: true,
        allowedEmailDomains: this.allowedEmailDomains,
        defaultMemberAttributes,
        hasNoAdditionalAttribute:
          Object.keys(member.attributes).sort().join('') ===
          BaseAttributeSlugs.sort().join(''),
        groupExceptions: this.groupExceptions
      },
      events: {
        submit: (memberObj: MemberObject) => {
          this.$emit('editMember', { memberObj, userId });
        }
      }
    });
  }

  async handleDeleteMember(member: MyjbiGroupMember) {
    const email = member.attributes.email || member.attributes.Email;

    const dialogElem: Vue = this.$buefy.dialog.confirm({
      title: `Delete ${email}`,
      message: `Deleting this user will also remove them from all subgroup(s) and revoke their access to product(s). This action cannot be undone.`,
      confirmText: 'Cancel',
      onConfirm: () => undefined,
      canCancel: ['button'],
      cancelText: 'Delete',
      onCancel: () => this.$emit('deleteMember', member.userId)
    });

    while (!dialogElem.$el?.querySelector) {
      await delay(50);
    }
    const cancelBtn = dialogElem.$el?.querySelector?.(
      `.modal-card > footer > button:first-child`
    );
    cancelBtn?.classList?.add?.(`is-red`);
  }

  async handleResendEmailNotification(member: MyjbiGroupMember) {
    const { userId, isActiveUser } = member;
    const groupId = this.groupId!;
    if (isActiveUser) {
      return;
    }
    try {
      ToastProgrammatic.open({
        queue: true,
        type: 'is-dark',
        position: 'is-top',
        message: `Resending User Invitation`
      });
      await this.resendEmailNotificationToGroupMember({ userId, groupId });
      ToastProgrammatic.open({
        queue: true,
        type: 'is-dark',
        position: 'is-top',
        message: `Resend User Invitation Successfully`
      });
    } catch (error: any) {
      ToastProgrammatic.open({
        queue: true,
        type: 'is-danger',
        position: 'is-top',
        message: error?.response?.data?.message || error,
        duration: 5000
      });
    }
  }

  onDragStart(event: any, columnData: any, dragColumnIndex: any) {
    event.dataTransfer.dropEffect = 'move';
    event.dataTransfer.effectAllowed = 'move';
    event.dataTransfer.setData('index', dragColumnIndex);
  }

  async onDrop(event: any, columnData: any, dropColumnIndex: any) {
    if (this.attributeSpecs) {
      const columnIndex = event.dataTransfer.getData('index');
      const dragColumnData = this.attributeSpecs[columnIndex];
      this.attributeSpecs.splice(columnIndex, 1);
      this.attributeSpecs.splice(dropColumnIndex, 0, dragColumnData);
      const updatedAttributeOrder = this.attributeSpecs.map((each) => {
        return each.groupUserAttribute.slug;
      });

      if (this.groupId) {
        const params = {
          groupId: this.groupId,
          attributeOrder: updatedAttributeOrder
        };
        this.upsertGroupAttributeOrder(params);
      }
      return this.attributeSpecs
        .filter((attr) => {
          const { slug } = attr.groupUserAttribute;
          return this.selectedAttrs
            .map((selectedAttr) => selectedAttr.id)
            .includes(slug);
        })
        .sort(
          (a, b) =>
            updatedAttributeOrder.indexOf(a.groupUserAttribute.slug) -
            updatedAttributeOrder.indexOf(b.groupUserAttribute.slug)
        );
    }
  }

  @Watch('attributes', { immediate: true })
  onAttributesChanged() {
    this.selectedAttrs = cloneDeep(this.attributes);
  }
}
