




































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { MyjbiGroupDetail } from '../../../jbi-shared/types/myjbi-group.types';
import { StringInputOption } from '../../../jbi-shared/types/form.types';
import { MemberObject } from '../../../utils/group.util';
import { ValidationObserver } from 'vee-validate';
import { RootState } from '../../../store/store';
import { isTruthy } from '../../../jbi-shared/util/watcher.vue-decorator';
import ExistingMembersSelector from '@/views/AdminCreateSubGroup/components/ExistingMembersSelector.vue';

@Component({
  components: {
    ValidationObserver,
    ExistingMembersSelector
  }
})
export default class AddFromParentModal extends Vue {
  @Prop(Array) parentGroupMembers!: MyjbiGroupDetail['members'];
  @Prop(Array)
  parentGroupAttributeSpecs!: MyjbiGroupDetail['groupUserAttributeSpecs'];

  membersData: MemberObject[] = [];
  notify = false;

  get isLoading() {
    return (this.$store.state as RootState).admin.apiState.addMembersToGroup
      .loading;
  }

  get isSucceeded() {
    return (this.$store.state as RootState).admin.apiState.addMembersToGroup
      .success;
  }

  @Watch('isSucceeded')
  @isTruthy
  onSucceeded() {
    // @ts-ignore
    this.$parent.close();
  }
}
