





































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { JAAS_APP } from '../../../jbi-shared/types/jaas-app.types';
import { RootState } from '../../../store/store';
import {
  isDifferent,
  isTruthy
} from '../../../jbi-shared/util/watcher.vue-decorator';
import {
  CreateGroupLicenseRequestPayload,
  UpdateGroupLicenseRequestPayload
} from '../../../store/modules/admin/types/group-license.types';
import dayjs from 'dayjs';
import { pluckNewValue } from '../../../jbi-shared/util/pluck.rx-operator';
import { filter, pluck } from 'rxjs/operators';
import { omit } from 'lodash';
import { Action } from 'vuex-class';
import { GetGroupStatsResponsePayload } from '../../../store/modules/admin/types/admin.types';
import { IPaginationOptions } from 'nestjs-typeorm-paginate';
import { GetGroupsProductResponsePayload } from '../../../store/modules/admin/types/group-product.types';

@Component({
  components: {
    ValidationObserver,
    ValidationProvider
  }
})
export default class LicenseSeatsConflictModal extends Vue {
  @Prop(String) groupProductName!: string;
  @Prop(String) groupName!: string;
  @Prop(Number) seats!: number;
}
