var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"modal-card"},[_c('header',{staticClass:"modal-card-head"},[_c('p',{class:[_vm.$style.title, 'modal-card-title']},[_vm._v(" "+_vm._s(_vm.isEdit ? 'Edit' : 'Add a')+" Member "+_vm._s(_vm.groupName ? ("in group \"" + _vm.groupName + "\"") : '')+" ")]),_c('button',{staticClass:"close-icon",attrs:{"type":"button"},on:{"click":function($event){return _vm.$parent.close()}}},[_c('i',{staticClass:"mdi mdi-close"})])]),_c('section',{staticClass:"modal-card-body",staticStyle:{"min-width":"600px"}},[(_vm.allowedEmailDomains && _vm.allowedEmailDomains.length)?_c('div',{staticClass:"m-b-1"},[_c('span',{class:_vm.$style.greyText},[_vm._v("Allowed Email Domains: ")]),_c('b',[_vm._v(" "+_vm._s(_vm.allowedEmailDomains.join(', '))+" ")])]):_vm._e(),(_vm.userProfile)?_c('div',{staticClass:"m-b-1"},[(_vm.userProfile.firstName)?_c('p',[_c('span',{class:_vm.$style.greyText},[_vm._v("First Name: ")]),_c('b',[_vm._v(_vm._s(_vm.userProfile.firstName))])]):_vm._e(),(_vm.userProfile.lastName)?_c('p',[_c('span',{class:_vm.$style.greyText},[_vm._v("Last Name: ")]),_c('b',[_vm._v(_vm._s(_vm.userProfile.lastName))])]):_vm._e(),(_vm.userProfile.email)?_c('p',[_c('span',{class:_vm.$style.greyText},[_vm._v("Email: ")]),_c('b',[_vm._v(_vm._s(_vm.userProfile.email))])]):_vm._e(),_c('div',{staticClass:"is-divider"})]):_vm._e(),_c('div',[(_vm.hasNoAdditionalAttribute)?_c('i',{class:_vm.$style.greyText},[_vm._v(" No group user attributes in this group. ")]):_c('div',[_vm._l((_vm.attributes),function(spec,specId){return _c('div',{key:specId,attrs:{"rules":{ required: spec.required },"name":spec.groupUserAttribute.name}},[_c('b-field',{class:spec.required ? _vm.$style.isRequired : '',attrs:{"label":spec.groupUserAttribute.name,"type":!_vm.values[spec.groupUserAttribute.slug].isValid ? 'is-danger' : '',"message":_vm.values[spec.groupUserAttribute.slug].errorMessage}},[(
                spec.groupUserAttribute.groupUserAttributeType.type === 'date'
              )?_c('b-datepicker',{attrs:{"icon-pack":"mdi","icon":"calendar","trap-focus":"","disabled":_vm.isEdit && !_vm.isAllowedToEditAttributes(!!spec.lockAttribute)},model:{value:(_vm.values[spec.groupUserAttribute.slug].value),callback:function ($$v) {_vm.$set(_vm.values[spec.groupUserAttribute.slug], "value", $$v)},expression:"values[spec.groupUserAttribute.slug].value"}},[_c('b-button',{class:_vm.$style.calendarCancelButton,attrs:{"label":"Clear"},on:{"click":function($event){_vm.values[spec.groupUserAttribute.slug].value = null}}})],1):(
                spec.groupUserAttribute.groupUserAttributeType.type ===
                'image'
              )?_c('ImageUploadComponent',{attrs:{"imageSrc":_vm.values[spec.groupUserAttribute.slug].value,"disabled":_vm.isEdit && !_vm.isAllowedToEditAttributes(!!spec.lockAttribute)},on:{"getImage":function (imageUrl) { return _vm.getImage(imageUrl, spec.groupUserAttribute.slug); },"isUploading":function (imageUploadStatus) { return _vm.isUploading(imageUploadStatus); }}}):(
                spec.groupUserAttribute.groupUserAttributeType.type === 'list'
              )?_c('ListDropdownComponent',{attrs:{"memberAttributeValue":_vm.values[spec.groupUserAttribute.slug].value,"groupUserAttributeSpec":spec,"isValid":_vm.values[spec.groupUserAttribute.slug].isValid,"disabled":_vm.isEdit && !_vm.isAllowedToEditAttributes(!!spec.lockAttribute)},on:{"getListValue":function (selectedListValues) { return _vm.getListValue(
                    selectedListValues,
                    spec.groupUserAttribute.slug
                  ); }}}):(
                spec.groupUserAttribute.groupUserAttributeType.type ===
                'text area'
              )?_c('div',{class:_vm.$style.textArea},[_c('quill-editor',{class:[
                  _vm.$style.fixedUrlBox,
                  _vm.values[spec.groupUserAttribute.slug].errorMessage
                    ? _vm.$style.errorBorder
                    : ''
                ],attrs:{"options":_vm.editorOption,"disabled":_vm.isEdit && !_vm.isAllowedToEditAttributes(!!spec.lockAttribute)},model:{value:(_vm.values[spec.groupUserAttribute.slug].value),callback:function ($$v) {_vm.$set(_vm.values[spec.groupUserAttribute.slug], "value", $$v)},expression:"values[spec.groupUserAttribute.slug].value"}})],1):_c('b-input',{attrs:{"type":_vm.isEmail(spec) ? 'email' : 'text',"disabled":_vm.shouldBeDisabled(spec, spec.groupUserAttribute.slug) ||
                (_vm.isEdit && !_vm.isAllowedToEditAttributes(!!spec.lockAttribute))},model:{value:(_vm.values[spec.groupUserAttribute.slug].value),callback:function ($$v) {_vm.$set(_vm.values[spec.groupUserAttribute.slug], "value", $$v)},expression:"values[spec.groupUserAttribute.slug].value"}})],1)],1)}),_c('b-checkbox',{staticClass:"m-t-1 m-b-1",attrs:{"disabled":_vm.isEdit ? !_vm.isEditMemberAllowed : !_vm.isAddMemberAllowed},model:{value:(_vm.notify),callback:function ($$v) {_vm.notify=$$v},expression:"notify"}},[_c('span',[_vm._v(" Notify members ")])])],2)])]),_c('footer',{staticClass:"modal-card-foot"},[_c('button',{staticClass:"button is-primary",class:_vm.isLoading ? 'is-loading' : '',attrs:{"type":"submit","disabled":!_vm.isFormValid || !_vm.isAllowedToSubmitForm},on:{"click":_vm.submitForm}},[_vm._v(" "+_vm._s(_vm.isEdit ? 'Edit' : 'Add')+" Member ")])])])}
var staticRenderFns = []

export { render, staticRenderFns }