































































































































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Action, State } from 'vuex-class';
import { RootState } from '../../../../store/store';
import { ApiState } from '../../../../store/types/general.types';
import {
  GroupLevelAttributeOption,
  GroupLevelAttributeValue,
  GroupTitleAndLogo
} from '../../../../store/modules/admin/types/group-level-attribute.types';
import { saveAs } from 'file-saver';
import { clone } from '@/jbi-shared/util/group-level-attributes.util';
import { isTruthy } from '@/jbi-shared/util/watcher.vue-decorator';
import dayjs from 'dayjs';

@Component({})
export default class GroupLevelAttributeView extends Vue {
  @Prop() groupId!: number;

  @Action('admin/getGroupLevelAttributeValuesByGroupId')
  getCurrentGroupAttributeValues!: (groupId: number) => void;

  @State(
    ({ admin }: RootState) =>
      admin.apiState.getGroupLevelAttributeValuesByGroupId
  )
  getGroupLevelAttributeValuesByGroupIdState!: ApiState;

  @State(({ admin }: RootState) => admin.groupLevelAttributeValuesByGroupId)
  public currentGroupAttributeValues!: GroupLevelAttributeValue[] | undefined;

  clonedAttributeValues: GroupLevelAttributeValue[] = [];

  public mounted() {
    this.getCurrentGroupAttributeValues(this.groupId);
  }

  attributeType(attribute: GroupLevelAttributeValue): string {
    return attribute.groupLevelAttributeType.type;
  }

  /* Addresses and coordinates requires extra massaging before rendering.
   * Empty values in field should not be shown. */
  renderAddress(attribute: GroupLevelAttributeValue): string {
    return (
      (attribute.value as GroupLevelAttributeOption[])
        .map((option) => option.value)
        .filter((value) => value)
        /* Value could be null or empty string. Display only the ones with content */
        .join(', ')
    );
  }

  getGoogleMapsUrl(latitude: string, longitude: string): string {
    return `https://www.google.com/maps/dir/${latitude},${longitude}`;
  }

  parseDate(date: string): string {
    return dayjs(date).format('DD MMM YYYY');
  }

  @isTruthy
  @Watch('getGroupLevelAttributeValuesByGroupIdState', { deep: true })
  getGroupLevelAttributeValuesByGroupIdStateSuccess(state: ApiState) {
    this.clonedAttributeValues.length = 0;

    if (state.success) {
      this.clonedAttributeValues = clone(
        this.currentGroupAttributeValues as GroupLevelAttributeValue[]
      );

      // remove logo from list to prevent rendering
      this.clonedAttributeValues.splice(1, 1);
      this.emitTitleAndLogo();
    }
  }

  emitTitleAndLogo() {
    // callback to emit title and logo value to parent for rendering
    const data: GroupTitleAndLogo = {
      title: '',
      logoUrl: ''
    };

    (this.currentGroupAttributeValues as GroupLevelAttributeValue[]).forEach(
      (attribute: GroupLevelAttributeValue) => {
        if (
          attribute.label.toLowerCase() === 'title' &&
          attribute.groupLevelAttributeType.type === 'text' &&
          attribute.order === 1
        ) {
          data.title = attribute.value as string;
        }

        if (
          attribute.label.toLowerCase() === 'logo' &&
          attribute.groupLevelAttributeType.type === 'images' &&
          attribute.order === 2
        ) {
          data.logoUrl = attribute.value as string;
        }
      }
    );

    this.$emit('groupTitleAndLogo', data);
  }

  downloadFile(fileUrl: string) {
    return saveAs(fileUrl, fileUrl.split('/').pop());
  }
}
