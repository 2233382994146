






































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { MyjbiGroupType } from '@/jbi-shared/types/myjbi-group.types';
import { Group } from '@/store/modules/admin/types/admin.types';
import { get as _get } from 'lodash';

@Component({
  components: {},
  name: 'GroupRow'
})
export default class GroupRow extends Vue {
  @Prop(Object) public group!: Group;
  @Prop(Function) public getChildren!: (group: Group) => Group[];
  @Prop({ type: Number, default: 1 }) public level!: number;
  @Prop(Boolean) public allGroupsExpanded!: boolean;
  @Prop(Boolean) public expandGroupFilter!: boolean;

  expanded = false;

  get descendants() {
    return this.getChildren(this.group);
  }

  get noSubGroup() {
    return !this.descendants.length;
  }

  get MyjbiGroupType() {
    return MyjbiGroupType;
  }

  get _get() {
    return _get;
  }

  @Watch('expandGroupFilter', { immediate: true })
  private onOpenGroupsExpandedChanged() {
    this.expanded = this.expandGroupFilter;
  }
}
