












































































import { Component, Prop } from 'vue-property-decorator';
import { State } from 'vuex-class';
import { mixins } from 'vue-class-component';
import BaseTable from '@/components/BaseTable.vue';
import BasePagination from '@/components/base/BasePagination.vue';
import BaseLoading from '@/components/base/BaseLoading.vue';
import { Group } from '@/store/modules/admin/types/admin.types';
import { PaginationMixin } from '@/components/base/pagination.mixin';
import { RootState } from '@/store/store';
import GroupRow from './GroupRow.vue';
import SortableTableHeader from '@/components/SortableTableHeader.vue';

@Component({
  components: {
    BaseTable,
    BasePagination,
    BaseLoading,
    GroupRow,
    SortableTableHeader
  }
})
export default class GroupList extends mixins(PaginationMixin) {
  @Prop() public items!: Group[];
  @Prop(Number) public currentPage!: number;
  @Prop(Number) public totalNumberOfPage!: number;
  @Prop(Number) public totalCount!: number;
  @Prop(Boolean) public isFirstPage!: boolean;
  @Prop(Boolean) public isLastPage!: boolean;
  @Prop(Number) public startItemIndex!: number;
  @Prop(Number) public endItemIndex!: number;
  @Prop({ type: Number, default: 1 }) public firstLvl!: number;
  @Prop(Boolean) public allGroupsExpanded!: boolean;
  @Prop(String) public groupLabel!: string;
  @Prop(Boolean) public expandGroupFilter!: boolean;
  @Prop(String) public sortColumn!: string;
  @Prop(String) public sortOrder!: 'ASC' | 'DESC';

  public groupRowKey: number = Math.floor(Math.random() * 999);

  @State((state: RootState) => state.admin.apiState.getGroups.success)
  public getGroupsListSuccess!: boolean;

  get groups() {
    return this.items || [];
  }

  get firstLvlGroups() {
    return this.groups?.filter((item) => item.nlevel === this.firstLvl);
  }

  get isPaginated() {
    return typeof this.totalNumberOfPage === 'number';
  }

  getChildren(group: Group) {
    const path = group.path;
    return this.groups.filter((g) => g.path === path + '.' + g.id);
  }

  public onClickHeaderColumn(columnName: string) {
    if (this.sortColumn === columnName) {
      return this.$emit('sort', {
        sortColumn: columnName,
        sortOrder: this.sortOrder === 'ASC' ? 'DESC' : 'ASC'
      });
    }
    this.$emit('sort', {
      sortColumn: columnName,
      sortOrder: 'DESC'
    });
    this.groupRowKey += 1;
  }

  public getSortOrderOfColumn(name: string) {
    if (this.sortColumn === name) {
      return this.sortOrder;
    }
    return 'ASC';
  }
}
