interface AppDetail {
  id: JAAS_APP;
  name: string;
  description: string;
  type: ApplicationType;
}

interface AppCardDetails {
  id: JAAS_APP;
  applicationImage: string;
  applicationLogo: string;
  applicationDescription: string;
}

interface AppProductDetails {
  id: JAAS_APP;
  productDescription: string;
  productDetails: string[];
}

export enum JAAS_APP {
  cplus = 'cplus',
  sumari = 'sumari',
  paces = 'paces',
  training = 'training',
  myjbi = 'myjbi',
}

export type JAAS_APP_PRODUCT = Exclude<
  JAAS_APP,
  JAAS_APP.training | JAAS_APP.myjbi
>;

export enum ApplicationType {
  General = 'General',
  TermsAndConditions = 'TermsAndConditions',
}

export const JAAS_APP_DETAIL: { [key in JAAS_APP]: AppDetail } = {
  cplus: {
    id: JAAS_APP.cplus,
    name: 'COnNECT+',
    description: `COnNECT+ system`,
    type: ApplicationType.TermsAndConditions,
  },
  sumari: {
    id: JAAS_APP.sumari,
    name: 'SUMARI',
    description: `The System for the Unified Management, Assessment and Review of Information`,
    type: ApplicationType.General,
  },
  paces: {
    id: JAAS_APP.paces,
    name: 'PACES',
    description: 'PACES system',
    type: ApplicationType.General,
  },
  training: {
    id: JAAS_APP.training,
    name: 'Training',
    description: 'JBI Training System',
    type: ApplicationType.General,
  },
  myjbi: {
    id: JAAS_APP.myjbi,
    name: 'MYJBI',
    description: 'MYJBI system',
    type: ApplicationType.TermsAndConditions,
  },
};

export const JAAS_APP_PRODUCT_DETAILS: {
  [key in JAAS_APP]: AppProductDetails;
} = {
  sumari: {
    id: JAAS_APP.sumari,
    productDescription:
      'SUMARI supports 10 review types, including reviews of effectiveness, qualitative research, economic evaluations, prevalence/incidence, aetiology/risk, mixed methods, umbrella/overviews, text/opinion, diagnostic test accuracy and scoping reviews. It facilitates the entire review process, from protocol development, team management, study selection, critical appraisal, data extraction, data synthesis and writing your systematic review report.',
    productDetails: [
      `Supports the entire review process, from drafting your protocol,
    study selection, critical appraisal, data extraction and synthesis`,
      `Allows you to manage review teams and contributors to your review`,
      `Designed based upon the guidance of the JBI Global, and
    as such all methods are supported and approved by the international
    scientific committee`,
    ],
  },
  paces: {
    id: JAAS_APP.paces,
    productDescription: `PACES (Practical Application of Clinical Evidence System) is a user-friendly online tool that makes it easy for health professionals to conduct efficient, timesaving audits in small or large health care settings.`,
    productDetails: [
      `Online support to facilitate a process of change in relation to
    the use of evidence in practice on a given activity or
    intervention.`,
      `Conduct, monitor, and review data collection.`,
      `Designed based upon the guidance of the JBI Global, and
    as such all methods are supported and approved by the international
    scientific committee`,
    ],
  },
  cplus: {
    id: JAAS_APP.cplus,
    productDescription: '',
    productDetails: [],
  },
  training: {
    id: JAAS_APP.training,
    productDescription: 'JBI Training',
    productDetails: [],
  },
  myjbi: {
    id: JAAS_APP.myjbi,
    productDescription: 'MYJBI',
    productDetails: [],
  },
};

export const JAAS_APP_CARD_DETAILS: { [key in JAAS_APP]: AppCardDetails } = {
  sumari: {
    id: JAAS_APP.sumari,
    applicationImage:
      '@/assets/images/subscriptions/jbisumari_thumbnail_img@2x.png',
    applicationLogo: '@/jbi-shared/assets/images/jbisumari_card_img.png',
    applicationDescription: `Our premier software for the systematic review of literature designed to assist researchers and practitioners in fields such as health, social sciences and humanities to conduct systematic reviews.`,
  },
  paces: {
    id: JAAS_APP.paces,
    applicationImage:
      '@/assets/images/subscriptions/jbipaces_thumbnail_img@2x.png',
    applicationLogo: '@/jbi-shared/assets/images/logo_paces@2x.png',
    applicationDescription: `PACES (Practical Application of Clinical Evidence System) is a
    user-friendly online tool that makes it easy for health professionals
    to conduct efficient, timesaving audits in small or large health care
    settings.`,
  },
  cplus: {
    id: JAAS_APP.cplus,
    applicationImage: '',
    applicationLogo: '',
    applicationDescription: '',
  },
  training: {
    id: JAAS_APP.training,
    applicationImage:
      '@/assets/images/subscriptions/jbitraining_thumbnail_img@2x.png',
    applicationLogo: '@/jbi-shared/assets/images/jbitraining_card_img.png',
    applicationDescription: 'JBI Training description',
  },
  myjbi: {
    id: JAAS_APP.myjbi,
    applicationImage: '',
    applicationLogo: '',
    applicationDescription: 'JBI Training description',
  },
};

export enum ApplicationCategory {
  Software = 'Software',
  Training = 'Training',
}
