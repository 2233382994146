













import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component({
  components: {}
})
export default class BtnViewAll extends Vue {
  expanded = false;

  @Watch('expanded')
  onExpandedChanged() {
    if (this.expanded) {
      this.$emit('expand');
    } else {
      this.$emit('collapse');
    }
  }
}
