





























































































































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import Container from '@/components/Container.vue';
import { Action, State } from 'vuex-class';
import {
  GroupUserAttributeTypeStatus,
  MyjbiGroupDetail,
  MyjbiGroupUserAttributeSpec
} from '@/jbi-shared/types/myjbi-group.types';
import { RootState } from '@/store/store';
import { Breadcrumb } from '../../components/BreadCrumbs.vue';
import GroupList from '@/views/AdminGroupManagement/components/GroupList.vue';
import MemberSection from './components/MemberSection.vue';
import BtnViewAll from '@/components/BtnViewAll.vue';
import {
  AddMembersToGroupErrorResponsePayload,
  AddMembersToGroupRequestPayload,
  DeleteGroupMemberRequestPayload,
  UpdateGroupMemberRequestPayload
} from '@/store/modules/admin/types/group.types';
import { MemberObject, userAttributesArrWithValues } from '@/utils/group.util';
import { ToastProgrammatic } from 'buefy';
import { isTruthy } from '@/jbi-shared/util/watcher.vue-decorator';
import { uniq } from 'lodash';
import { AxiosError } from 'axios';
import LicenseFormModal from '@/views/AdminGroupManagement/components/LicenseFormModal.vue';
import {
  GroupLicense,
  UpdateGroupLicenseRequestPayload
} from '@/store/modules/admin/types/group-license.types';
import LicenseSeatsConflictModal from '@/views/AdminViewGroup/components/LicenseSeatsConflictModal.vue';
import ellipsize from 'ellipsize';
import { GroupTitleAndLogo } from '@/store/modules/admin/types/group-level-attribute.types';
import GroupLevelAttributeView from '../AdminGroupSettings/components/GroupLevelAttributes/GroupLevelAttributeView.vue';
import {
  FilteredSubGroupPayload,
  GetSubGroupsResponsePayload
} from '@/store/modules/admin/types/admin.types';
import BasePaginatorHoc from '../../components/base/BasePaginatorHoc.vue';
import { ApiState } from '@/store/types/general.types';
import {
  PermissionsMatrixActionsEnum,
  ResourceExceptions
} from '@/store/modules/roles-and-permissions/types/roles-and-permissions.types';
import { isUserAllowed } from '@/utils/rbac.util';
import { EntityTypes } from '@/store/modules/module-tree/enums/module-tree.enums';

@Component({
  computed: {
    PermissionsMatrixActionsEnum() {
      return PermissionsMatrixActionsEnum;
    }
  },
  components: {
    Container,
    GroupList,
    MemberSection,
    BtnViewAll,
    LicenseFormModal,
    LicenseSeatsConflictModal,
    GroupLevelAttributeView,
    BasePaginatorHoc
  }
})
export default class AdminViewGroup extends Vue {
  @Action('admin/getGroupDetail')
  getGroupDetail!: (params: { id: number; isMembers: boolean }) => void;

  @Action('admin/getSubGroups')
  getSubGroups!: (params: {
    id: number;
    params: FilteredSubGroupPayload;
  }) => void;

  @Action('admin/getGroupAttributeOrderByGroupId')
  getGroupAttributeOrderByGroupId!: (groupId: number) => void;

  @Action('admin/addMembersToGroup')
  addMembersToGroup!: (
    payload: AddMembersToGroupRequestPayload
  ) => Promise<void>;

  @Action('admin/editGroupMember')
  editGroupMember!: (payload: UpdateGroupMemberRequestPayload) => Promise<void>;

  @Action('admin/deleteGroupMember')
  deleteGroupMember!: (
    payload: DeleteGroupMemberRequestPayload
  ) => Promise<void>;

  @Action('admin/getParentGroupDetailByChildGroupId')
  getParentGroupDetailByChildGroupId!: (
    id: number
  ) => Promise<MyjbiGroupDetail>;

  @Action('admin/getMasterGroupEmailDomains')
  getMasterGroupEmailDomains!: (id: number) => Promise<string[]>;

  @Action('admin/updateGroupLicense')
  updateGroupLicense!: (
    options: UpdateGroupLicenseRequestPayload
  ) => Promise<GroupLicense>;

  @Action('rolesAndPermissions/getGroupExceptions')
  getGroupExceptions!: (groupId: number) => Promise<ResourceExceptions>;

  @State((state: RootState) => state.rolesAndPermissions.groupExceptions)
  public groupExceptions!: ResourceExceptions;

  @State((state: RootState) => state.admin.subGroups)
  public subGroups!: GetSubGroupsResponsePayload;

  @State(({ admin }: RootState) => admin.apiState.getGroupDetail)
  public groupDetailSuccess!: ApiState;

  public allGroupsExpanded = false;
  public perPage = 50;
  public page = 1;
  public sortColumn: string = 'name';
  public sortOrder: 'ASC' | 'DESC' = 'ASC';
  public memberSectionKey: number = Math.floor(Math.random() * 999);
  groupTitle: string = '';
  groupLogoUrl: string = '';

  public isUserAllowed(
    action: PermissionsMatrixActionsEnum,
    module: string,
    skipImplicitCheck?: boolean
  ): boolean {
    const groupTypeName = this.groupDetail
      ? this.groupDetail.types[0]
      : undefined;
    return isUserAllowed(
      action,
      module,
      EntityTypes.GROUP,
      groupTypeName,
      this.groupId,
      this.groupExceptions,
      skipImplicitCheck
    );
  }

  get groupList() {
    return GroupList;
  }

  get isLocalOrDev() {
    return (
      process.env.VUE_APP_ENV === 'local' ||
      process.env.VUE_APP_ENV === 'development'
    );
  }

  get groupDetail() {
    this.memberSectionKey += 1;
    return (this.$store.state as RootState).admin.groupDetail;
  }

  get masterGroupEmailDomains() {
    return (this.$store.state as RootState).admin.masterGroupEmailDomains;
  }

  get parentGroupDetailByChildGroupId() {
    return (this.$store.state as RootState).admin
      .parentGroupDetailByChildGroupId;
  }

  get groupId() {
    return +this.$route.params.groupId;
  }

  get membersCount() {
    return this.groupDetail?.members?.length || 0;
  }

  get subGroupsCount() {
    return this.groupDetail?.subGroups?.length || 0;
  }

  get groupProductName() {
    const name = this.groupDetail?.license?.groupProduct?.name;
    return name ? ellipsize(name, 60) : name;
  }

  get licenseSeats() {
    return this.groupDetail?.license?.seats;
  }

  get licenseStatus() {
    return this.groupDetail?.license?.isActive;
  }

  get licenseId() {
    return this.groupDetail?.license?.id;
  }

  get attributeOrder() {
    return (this.$store.state as RootState).admin.groupAttributeOrder;
  }

  get breadcrumbs(): Breadcrumb[] {
    if (!this.groupDetail) {
      return [];
    }
    const root: Breadcrumb = {
      route: {
        name: 'admin-group-management',
        query: {
          tab: 'Groups'
        }
      },
      text: 'Groups'
    };
    const ancestors: Breadcrumb[] =
      this.groupDetail?.ancestorGroups
        ?.slice()
        .sort((a, b) => {
          return a?.nlevel - b?.nlevel;
        })
        ?.map((g) => {
          return {
            text: g.name,
            ...(g.userHasAccess && {
              route: {
                name: 'admin-view-group',
                params: { groupId: String(g.id) }
              }
            })
          };
        }) || [];
    const current: Breadcrumb = {
      text: this.groupDetail?.name!
    };
    return [root, ...ancestors, current];
  }

  get isProtected(): boolean {
    return this.groupDetail?.protectedTypes?.isProtected || false;
  }

  get protectedTypeName() {
    return this.groupDetail?.protectedTypes?.protectedType?.name || undefined;
  }

  get groupLvl() {
    return this.groupDetail?.nlevel || 1000;
  }

  get canCreateSubGroup() {
    return this.groupLvl < 4;
  }

  get addMembersToGroupSuccess() {
    return (this.$store.state as RootState)?.admin?.apiState?.addMembersToGroup
      .success;
  }

  get editGroupMemberSuccess() {
    return (this.$store.state as RootState)?.admin?.apiState?.editGroupMember
      .success;
  }

  get deleteGroupMemberSuccess() {
    return (this.$store.state as RootState)?.admin?.apiState?.deleteGroupMember
      .success;
  }

  get hasMultiLvlSubGroups() {
    const nlevels = this.subGroups.items?.map((g) => g.nlevel);
    return uniq(nlevels).length > 1;
  }

  get allAttributeSpecs() {
    if (this.groupDetail && this.groupDetail.groupUserAttributeSpecs) {
      const allAttributeSpecs: MyjbiGroupUserAttributeSpec[] = [
        ...this.groupDetail.groupUserAttributeSpecs,
        {
          groupUserAttribute: {
            id: 0,
            name: 'Email Verified',
            slug: 'user-profile-email-verified-1001',
            option: null,
            status: GroupUserAttributeTypeStatus.ACTIVE,
            groupUserAttributeType: {
              id: 1,
              type: 'text',
              option: null,
              status: GroupUserAttributeTypeStatus.ACTIVE
            }
          },
          required: false,
          isDefault: true
        },
        {
          groupUserAttribute: {
            id: 0,
            name: 'User Status',
            slug: 'user-profile-user-status-1002',
            option: null,
            status: GroupUserAttributeTypeStatus.ACTIVE,
            groupUserAttributeType: {
              id: 1,
              type: 'text',
              option: null,
              status: GroupUserAttributeTypeStatus.ACTIVE
            }
          },
          required: false,
          isDefault: true
        },
        {
          groupUserAttribute: {
            id: 0,
            name: 'Username',
            slug: 'user-profile-username-1003',
            option: null,
            status: GroupUserAttributeTypeStatus.ACTIVE,
            groupUserAttributeType: {
              id: 1,
              type: 'text',
              option: null,
              status: GroupUserAttributeTypeStatus.ACTIVE
            }
          },
          required: false,
          isDefault: true
        }
      ];

      if (this.attributeOrder?.attributeOrder) {
        const newAttributesOrder: string[] = [];
        const existingAttributeOrder: string[] = this.attributeOrder
          .attributeOrder;
        allAttributeSpecs.forEach((attribute) => {
          const { slug } = attribute.groupUserAttribute;
          if (!existingAttributeOrder.includes(slug)) {
            newAttributesOrder.push(slug);
          }
        });
        const order = uniq([...existingAttributeOrder, ...newAttributesOrder]);

        return allAttributeSpecs.sort(
          (a, b) =>
            order.indexOf(a.groupUserAttribute.slug) -
            order.indexOf(b.groupUserAttribute.slug)
        );
      }

      return allAttributeSpecs;
    }
  }

  get getGroupDetailError() {
    return (this.$store.state as RootState)?.admin?.apiState?.getGroupDetail
      .error;
  }

  get allowedEmailDomains() {
    // get email domains from current group details
    const emailDomainFromCurrentId = this.groupDetail?.emailDomains;

    // get email domains from root ancestor
    const emailDomainFromAncestor = this.masterGroupEmailDomains;

    // if this group detail has email domains, use that
    if (emailDomainFromCurrentId && emailDomainFromCurrentId.length > 0) {
      return emailDomainFromCurrentId;
      // if not, use the one you get from root ancestor (master group)
    } else if (emailDomainFromAncestor && emailDomainFromAncestor.length > 0) {
      return emailDomainFromAncestor;
    }
  }

  get isUserAllowedToViewBasicDetails() {
    return this.isUserAllowed(
      PermissionsMatrixActionsEnum.READ,
      'group_administration-groups-read_groups-read_basic_details',
      true
    );
  }

  public handleSort(data: any) {
    this.sortColumn = data.sortColumn;
    this.sortOrder = data.sortOrder;
    if (this.groupDetail) {
      this.getSubGroups({
        id: this.groupId,
        params: {
          nlevel: this.groupDetail.nlevel + 1,
          sortOrder: this.sortOrder,
          sortColumn: this.sortColumn,
          page: this.page,
          limit: this.perPage
        }
      });
    }
  }

  public handlePaginator({ perPage, page }: { perPage: number; page: number }) {
    this.perPage = perPage;
    this.page = page;
    if (this.groupDetail) {
      this.getSubGroups({
        id: this.groupId,
        params: {
          nlevel: this.groupDetail.nlevel + 1,
          sortOrder: this.sortOrder,
          sortColumn: this.sortColumn,
          page: this.page,
          limit: this.perPage
        }
      });
    }
  }

  async handleAddMember({
    values,
    notify
  }: {
    values: MemberObject;
    notify: boolean;
  }) {
    try {
      await this.addMembersToGroup({
        groupId: this.groupDetail?.id!,
        userAttributesArr:
          [values] && this.allAttributeSpecs
            ? userAttributesArrWithValues([values], this.allAttributeSpecs)
            : [],
        notify
      });
      ToastProgrammatic.open({
        queue: true,
        type: 'is-dark',
        position: 'is-top',
        message: `1 Member(s) added`
      });
    } catch (error: any) {
      this.handleAddMemberError(error);
    }
  }

  async handleEditMember({
    memberObj,
    userId
  }: {
    memberObj: { values: MemberObject };
    userId: number;
  }) {
    try {
      await this.editGroupMember({
        groupId: this.groupDetail?.id!,
        userId,
        userAttributesArr:
          [memberObj.values] && this.allAttributeSpecs
            ? userAttributesArrWithValues(
                [memberObj.values],
                this.allAttributeSpecs
              )
            : []
      });
    } catch (error: any) {
      ToastProgrammatic.open({
        queue: true,
        type: 'is-danger',
        position: 'is-top',
        message: error?.response?.data?.message || error,
        duration: 5000
      });
    }
  }

  async handleDeleteMember(userId: number) {
    try {
      await this.deleteGroupMember({
        groupId: this.groupDetail?.id!,
        userId
      });
    } catch (error: any) {
      ToastProgrammatic.open({
        queue: true,
        type: 'is-danger',
        position: 'is-top',
        message: error?.response?.data?.message || error,
        duration: 5000
      });
    }
  }

  async handleAddMultipleMembers({
    values,
    notify
  }: {
    values: MemberObject[];
    notify: boolean;
  }) {
    try {
      await this.addMembersToGroup({
        groupId: this.groupDetail?.id!,
        userAttributesArr:
          values && this.allAttributeSpecs
            ? userAttributesArrWithValues(values, this.allAttributeSpecs)
            : [],
        notify
      });

      ToastProgrammatic.open({
        queue: true,
        type: 'is-dark',
        position: 'is-top',
        message: `${values?.length} Member(s) added`
      });
    } catch (error: any) {
      this.handleAddMemberError(error);
    }
  }

  async handleAddFromParent({
    values,
    notify
  }: {
    values: MemberObject[];
    notify: boolean;
  }) {
    try {
      await this.addMembersToGroup({
        groupId: this.groupDetail?.id!,
        userAttributesArr:
          values && this.allAttributeSpecs
            ? userAttributesArrWithValues(values, this.allAttributeSpecs)
            : [],
        notify
      });

      ToastProgrammatic.open({
        queue: true,
        type: 'is-dark',
        position: 'is-top',
        message: `${values?.length} Member(s) added`
      });
    } catch (error: any) {
      this.handleAddMemberError(error);
    }
  }

  async handleAddExistingMembers({
    values,
    notify
  }: {
    values: MemberObject[];
    notify: boolean;
  }) {
    try {
      await this.addMembersToGroup({
        groupId: this.groupDetail?.id!,
        userAttributesArr:
          values && this.allAttributeSpecs
            ? userAttributesArrWithValues(values, this.allAttributeSpecs)
            : [],
        notify
      });

      ToastProgrammatic.open({
        queue: true,
        type: 'is-dark',
        position: 'is-top',
        message: `${values?.length} Member(s) added`
      });
    } catch (error: any) {
      this.handleAddMemberError(error);
    }
  }

  handleAddMemberError(error: AxiosError) {
    if (error?.response?.status === 409) {
      this.openLicenseSeatsConflictModal(error.response?.data);
    } else {
      ToastProgrammatic.open({
        queue: true,
        type: 'is-danger',
        position: 'is-top',
        message: error?.response?.data?.message || error,
        duration: 5000
      });
    }
  }

  openLicenseSeatsConflictModal(
    errorResponse: AddMembersToGroupErrorResponsePayload
  ) {
    const { seats, groupProductName, groupName } = errorResponse;
    const { close } = this.$buefy.modal.open({
      parent: this,
      component: LicenseSeatsConflictModal,
      hasModalCard: true,
      trapFocus: true,
      props: {
        groupProductName,
        groupName,
        seats
      },
      events: {
        open: () => {
          this.openEditLicenseModal(errorResponse, close);
        }
      }
    });
  }

  openEditLicenseModal(
    errorResponse: AddMembersToGroupErrorResponsePayload,
    closeLicenseSeatsConflictModal: () => void
  ) {
    const { close } = this.$buefy.modal.open({
      parent: this,
      component: LicenseFormModal,
      hasModalCard: true,
      trapFocus: true,
      props: {
        id: errorResponse.id,
        name: errorResponse.name,
        startAt: errorResponse.startAt,
        endAt: errorResponse.endAt,
        seats: errorResponse.seats,
        groupMemberCount: errorResponse.groupMemberCount,
        groupId: errorResponse.groupId,
        groupProductId: errorResponse.groupProductId
      },
      events: {
        submit: async (v: UpdateGroupLicenseRequestPayload) => {
          await this.handleEditLicense(v);
          close();
          closeLicenseSeatsConflictModal();

          ToastProgrammatic.open({
            queue: true,
            type: 'is-dark',
            position: 'is-top',
            message: `Changes saved`
          });
        }
      }
    });
  }

  async handleEditLicense(values: UpdateGroupLicenseRequestPayload) {
    try {
      await this.updateGroupLicense(values);
    } catch (error: any) {
      ToastProgrammatic.open({
        queue: true,
        type: 'is-danger',
        position: 'is-top',
        message: error?.response?.data?.message || error,
        duration: 5000
      });
    }
  }

  public async parentEmailDomains() {
    const parentEmailDomains = this.parentGroupDetailByChildGroupId
      ?.emailDomains;
    if (parentEmailDomains) {
      return parentEmailDomains;
    } else {
      return [];
    }
  }

  handleTitleAndLogo(data: GroupTitleAndLogo) {
    this.groupTitle = data.title;
    this.groupLogoUrl = data.logoUrl;
  }

  @Watch('groupId', { immediate: true })
  onGroupIdChanged() {
    this.getGroupDetail({ id: this.groupId, isMembers: false });
    this.getGroupAttributeOrderByGroupId(this.groupId);
    this.getParentGroupDetailByChildGroupId(this.groupId);
  }

  @Watch('deleteGroupMemberSuccess')
  @Watch('editGroupMemberSuccess')
  @Watch('addMembersToGroupSuccess')
  @isTruthy
  onAddMemberSuccess() {
    this.getGroupDetail({ id: this.groupId, isMembers: false });
    this.getParentGroupDetailByChildGroupId(this.groupId);
  }

  @Watch('groupDetailSuccess')
  @isTruthy
  onGroupDetailSuccess() {
    if (this.groupDetail) {
      if (
        this.isUserAllowed(
          PermissionsMatrixActionsEnum.READ,
          'group_administration-groups-read_groups-read_subgroups',
          true
        )
      ) {
        this.getSubGroups({
          id: this.groupId,
          params: {
            nlevel: this.groupDetail.nlevel + 1,
            sortOrder: this.sortOrder,
            sortColumn: this.sortColumn,
            page: this.page,
            limit: this.perPage
          }
        });
      }
    }
  }

  @Watch('getGroupDetailError')
  onGetGroupDetailError(error: AxiosError) {
    // that should be a global interceptor to handle data not found
    if (error?.response?.status === 404) {
      return this.$router.replace({ name: 'page-not-found' });
    }
  }

  public mounted() {
    this.getGroupExceptions(this.groupId);
    if (
      this.isUserAllowed(
        PermissionsMatrixActionsEnum.READ,
        'group_administration-groups-read_groups-read_email_domain',
        true
      )
    ) {
      this.getMasterGroupEmailDomains(this.groupId);
    }
  }
}
