









































































































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import {
  MyjbiGroupDetail,
  MyjbiGroupUserAttributeSpec
} from '@/jbi-shared/types/myjbi-group.types';
import { isDifferent, isTruthy } from '@/jbi-shared/util/watcher.vue-decorator';
import { RootState } from '@/store/store';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { Action, State } from 'vuex-class';
import {
  UserByEmailResponsePayload,
  GroupAttributeOrderResponsePayload
} from '@/store/modules/admin/types/admin.types';
import { Debounce } from '@/jbi-shared/util/debounce.vue-decorator';
import { MyjbiGroupMember } from '@/jbi-shared/types/myjbi-group.types';
import ImageUploadComponent from '../../AdminCreateGroup/components/UserLevelAttributes/ImageUploadComponent.vue';
import ListDropdownComponent from '../../AdminCreateGroup/components/UserLevelAttributes/ListDropdownComponent.vue';
import {
  isValidGroupUserAttributeValue,
  MemberObject
} from '@/utils/group.util';
import { ApiState } from '@/store/types/general.types';
import {
  Profile,
  BaseAttributeSlugs,
  BaseUserDetailsSlugs
} from '@/store/modules/profile/profile.state';
import {
  PermissionsMatrixActionsEnum,
  ResourceExceptions
} from '@/store/modules/roles-and-permissions/types/roles-and-permissions.types';
import { isUserAllowed } from '@/utils/rbac.util';
import { EntityTypes } from '@/store/modules/module-tree/enums/module-tree.enums';

@Component({
  components: {
    ValidationObserver,
    ValidationProvider,
    ImageUploadComponent,
    ListDropdownComponent
  }
})
export default class SingleMemberModal extends Vue {
  @Prop(Array) attributeSpecs!: MyjbiGroupDetail['groupUserAttributeSpecs'];
  @Prop(String) type!: 'add' | 'edit';
  @Prop(Object) member!: MyjbiGroupMember;
  @Prop({ default: false }) isEdit!: boolean;
  @Prop() allowedEmailDomains!: string[];
  @Prop(Number) groupIndex!: number;
  @Prop(String) groupName!: string;
  @Prop(Number) groupId!: number;
  @Prop(Boolean) hasNoAdditionalAttribute!: boolean;
  @Prop(Boolean) isProfilePage!: boolean;
  @Prop(Object) defaultMemberAttributes!: Profile;
  @Prop() groupTypeName!: string;
  @Prop() groupExceptions!: ResourceExceptions;

  @Action('admin/getUserByEmail')
  getUserByEmail!: (email: string) => Promise<void>;

  @State(({ admin }: RootState) => admin.userByEmailResponse)
  userByEmailResponse!: UserByEmailResponsePayload;

  @Action('admin/getGroupAttributeOrderByGroupId')
  getGroupAttributeOrderByGroupId!: (groupId: number) => void;

  @State(({ admin }: RootState) => admin.apiState.getGroupAttributeOrder)
  groupAttributeOrderApiState!: ApiState;

  @State(({ admin }: RootState) => admin.groupAttributeOrder)
  customAttributeOrder!: GroupAttributeOrderResponsePayload;

  notify = false;
  isFormValid = false;
  firstNameDisabled = false;
  lastNameDisabled = false;
  checkIsEmail = /^[^\s@]+@[^\s@]+$/;
  values: MemberObject = this.userDetails;

  public isUserAllowed(
    action: PermissionsMatrixActionsEnum,
    module: string,
    skipImplicitCheck?: boolean
  ): boolean {
    const instance = EntityTypes.GROUP + '_' + this.groupId;
    return isUserAllowed(
      action,
      module,
      EntityTypes.GROUP,
      this.groupTypeName,
      this.groupId,
      this.groupExceptions,
      skipImplicitCheck
    );
  }

  get isAllowedToSubmitForm() {
    if (this.isEdit) {
      return (
        this.isAllowedToEditLockedAttributes ||
        this.isAllowedToEditUnlockedAttributes
      );
    } else {
      return this.isAddMemberAllowed;
    }
  }

  isAllowedToEditAttributes(isLockedAttribute: boolean) {
    if (isLockedAttribute) {
      return this.isAllowedToEditLockedAttributes;
    } else {
      return this.isAllowedToEditUnlockedAttributes;
    }
  }

  get isAllowedToEditLockedAttributes(): boolean {
    return this.isUserAllowed(
      PermissionsMatrixActionsEnum.UPDATE,
      'group_administration-groups-update_groups-update_members-update_member_details-update_locked_attributes',
      true
    );
  }

  get isAllowedToEditUnlockedAttributes(): boolean {
    return this.isUserAllowed(
      PermissionsMatrixActionsEnum.UPDATE,
      'group_administration-groups-update_groups-update_members-update_member_details-update_unlocked_attributes',
      true
    );
  }

  get isAddMemberAllowed(): boolean {
    return this.isUserAllowed(
      PermissionsMatrixActionsEnum.CREATE,
      'group_administration-groups-update_groups-update_members-create_members-create_new_members',
      true
    );
  }

  get isEditMemberAllowed(): boolean {
    return this.isUserAllowed(
      PermissionsMatrixActionsEnum.UPDATE,
      'group_administration-groups-update_groups-update_members-update_member_details',
      true
    );
  }

  mounted() {
    if (this.groupId) {
      this.getGroupAttributeOrderByGroupId(this.groupId);
    }

    /**
     * Workaround to override Quill's default link field placeholder in tooltip
     * https://github.com/quilljs/quill/issues/1107#issuecomment-259938173
     */
    const linkTooltip = document.querySelector('.ql-snow .ql-tooltip');
    const urlInput = (linkTooltip as any).querySelector('input[data-link]');

    if (urlInput) {
      urlInput.dataset.link = 'URL with https://';
    }
  }

  get editorOption() {
    return {
      modules: {
        toolbar: [
          ['bold', 'italic', 'underline', 'link'],
          [{ list: 'ordered' }, { list: 'bullet' }],
          [{ header: [1, 2, 3, 4, 5, 6, false] }]
        ]
      },
      placeholder: 'Ctrl/Cmd + Shift + V to paste without formatting'
    };
  }

  get userProfile(): Profile {
    if (this.defaultMemberAttributes) {
      return this.defaultMemberAttributes;
    }
    return (this.$store.state as RootState).admin.userProfile as Profile;
  }

  get userDetails(): MemberObject {
    const values: MemberObject = {};
    if (this.attributeSpecs) {
      this.attributeSpecs.forEach((attrSpec: MyjbiGroupUserAttributeSpec) => {
        const {
          slug,
          groupUserAttributeType,
          groupUserAttributeValue
        } = attrSpec.groupUserAttribute;
        let attrValue = '';
        if (groupUserAttributeValue && groupUserAttributeValue.length > 0) {
          const value = groupUserAttributeValue[this.groupIndex]
            ? groupUserAttributeValue[this.groupIndex].value
            : groupUserAttributeValue[0].value;
          attrValue = value;
        } else {
          attrValue = this.member?.attributes[slug];
        }
        values[slug] = {
          value: attrValue ? attrValue : '',
          isRequired: attrSpec.required ? true : false,
          isValid: true,
          errorMessage: null
        };
        if (groupUserAttributeType.type === 'date') {
          values[slug].value = attrValue ? new Date(attrValue) : null;
        }
      });
    }
    return values;
  }

  isEmail(spec: MyjbiGroupUserAttributeSpec) {
    return spec.groupUserAttribute.name?.toLowerCase() === 'email';
  }

  getImage(imageUrl: string, slug: string) {
    this.values[slug].value = imageUrl;
  }

  isUploading(status: boolean) {
    this.isFormValid = !status;
  }

  getListValue(listValues: string, slug: string) {
    this.values[slug].value = listValues;
  }

  shouldBeDisabled(spec: MyjbiGroupUserAttributeSpec, slug: string) {
    switch (slug) {
      case 'first-name':
        if (this.member && this.member.userId) {
          this.firstNameDisabled = true;
        }
        return this.firstNameDisabled;
      case 'last-name':
        if (this.member && this.member.userId) {
          this.lastNameDisabled = true;
        }
        return this.lastNameDisabled;
      default:
        // disallow editing of email
        return this.member && this.isEmail(spec);
    }
  }

  get attributes(): MyjbiGroupUserAttributeSpec[] {
    if (!this.attributeSpecs) {
      return [];
    }

    const userDetailAttributes = this.attributeSpecs.filter(
      (attribute: MyjbiGroupUserAttributeSpec) => {
        return BaseUserDetailsSlugs.includes(attribute.groupUserAttribute.slug);
      }
    );

    const nonDefaultAttributes = this.attributeSpecs.filter(
      (attribute: MyjbiGroupUserAttributeSpec) => {
        return (
          BaseAttributeSlugs.includes(attribute.groupUserAttribute.slug) ===
          false
        );
      }
    );

    /**
     * There are 4 conditions that needed to be checked.
     * 1. member (undefined indicates modal is being used to add new member)
     * 2. nonDefaultAttributes (additional attributes that are not first/lastname/email)
     * 3. customAttributeOrder (sorts attribute according to headers in view group page)
     * 4. parent-child rship?????
     */
    if (nonDefaultAttributes.length === 0 && !this.member) {
      return userDetailAttributes;
    } else {
      if (
        !this.customAttributeOrder ||
        (this.customAttributeOrder &&
          this.customAttributeOrder.attributeOrder &&
          this.customAttributeOrder.attributeOrder.length === 0)
      ) {
        return [
          ...(this.member ? [] : userDetailAttributes),
          ...nonDefaultAttributes
        ];
      } else {
        /**
         * Sort editable attributes according to custom attribute ordering
         *
         * Note:
         * Attribute ordering is ONLY created and updated
         * when admin drags and drops the sortable table headers in member list.
         * This means that if admin adds a new attribute to the group,
         * but they did not drag and drop the table headers,
         * the new attribute will be missing from the database record.
         */
        const editableAttrSlugs: string[] = nonDefaultAttributes.map(
          (attribute) => attribute.groupUserAttribute.slug
        );

        const customSortedSlugs: string[] = this.customAttributeOrder.attributeOrder.filter(
          (savedSlug) => {
            return (
              BaseAttributeSlugs.includes(savedSlug) === false &&
              editableAttrSlugs.includes(savedSlug) === true
            );
          }
        );

        const unsortedAttributes = nonDefaultAttributes.filter((attr) => {
          return (
            customSortedSlugs.includes(attr.groupUserAttribute.slug) === false
          );
        });

        if (customSortedSlugs.length === 0) {
          return nonDefaultAttributes;
        }

        const numberOfAttributesToSort: number = customSortedSlugs.length;
        const sortedAttributes: MyjbiGroupUserAttributeSpec[] = [];

        for (let i = 0; i < nonDefaultAttributes.length; i++) {
          if (i < numberOfAttributesToSort) {
            const targetAttribute = nonDefaultAttributes.find((attribute) => {
              const sortIndex = i === numberOfAttributesToSort ? i - 1 : i;
              return (
                attribute.groupUserAttribute.slug ===
                customSortedSlugs[sortIndex]
              );
            });

            if (targetAttribute) {
              sortedAttributes.push(
                targetAttribute as MyjbiGroupUserAttributeSpec
              );
            }
          }
        }

        return [
          ...(this.member ? [] : userDetailAttributes),
          ...sortedAttributes,
          ...unsortedAttributes
        ];
      }
    }
  }

  validateForm() {
    this.attributes.forEach((attribute: MyjbiGroupUserAttributeSpec) => {
      const { slug } = attribute.groupUserAttribute;

      const { isValid, errorMessage } = isValidGroupUserAttributeValue(
        attribute,
        this.values[slug].value,
        this.allowedEmailDomains
      );

      this.values[slug].isValid = isValid;
      this.values[slug].errorMessage = errorMessage;
    });
    for (const attributes of this.attributes) {
      const { slug } = attributes.groupUserAttribute;
      if (!this.values[slug].isValid) {
        this.isFormValid = false;
        break;
      } else {
        this.isFormValid = true;
      }
    }
  }

  submitForm() {
    this.validateForm();
    if (this.isFormValid) {
      this.$emit('submit', { values: this.values, notify: this.notify });
    }
  }

  get isLoading() {
    return (
      (this.$store.state as RootState).admin.apiState.addMembersToGroup
        .loading ||
      (this.$store.state as RootState).admin.apiState.editGroupMember.loading
    );
  }

  get isAddSucceeded() {
    return (this.$store.state as RootState).admin.apiState.addMembersToGroup
      .success;
  }

  get isEditSucceeded() {
    return (this.$store.state as RootState).admin.apiState.editGroupMember
      .success;
  }

  @Watch('values.email.value')
  @Debounce(400)
  @isTruthy
  @isDifferent
  emailChangeHandler(newValue: string) {
    if (this.checkIsEmail.test(newValue)) {
      this.getUserByEmail(newValue);
    }
  }

  @Watch('isAddSucceeded')
  @Watch('isEditSucceeded')
  @isTruthy
  onSucceeded() {
    // @ts-ignore
    this.$parent.close();
  }

  @Watch('userByEmailResponse')
  @isDifferent
  @isTruthy
  async userByEmailResponseHandler(
    responsePayload: UserByEmailResponsePayload
  ) {
    if (responsePayload.userExist && responsePayload.user) {
      if (responsePayload.user.firstName) {
        const data = {
          value: responsePayload.user.firstName,
          isRequired: true,
          isValid: true,
          errorMessage: null
        };
        Vue.set(this.values, 'first-name', data);
        this.firstNameDisabled = true;
      }

      if (responsePayload.user.lastName) {
        const data = {
          value: responsePayload.user.lastName,
          isRequired: true,
          isValid: true,
          errorMessage: null
        };
        Vue.set(this.values, 'last-name', data);
        this.lastNameDisabled = true;
      }
    } else {
      this.firstNameDisabled = false;
      this.lastNameDisabled = false;
    }
  }

  @Watch('values', { deep: true })
  @Debounce(400)
  public watchChangeValues() {
    this.validateForm();
  }
}
