
























import { Component, Vue, Prop } from 'vue-property-decorator';

export interface DropdownOption {
  text: string;
  // tslint:disable-next-line
  handler: Function;
  textColor?: string;
}

@Component({})
export default class Dropdown extends Vue {
  @Prop(String) public position!: string;
  @Prop(Array) public options!: DropdownOption[];
  @Prop(String) triggerLabel!: string;
  @Prop(String) leftIcon!: string;
}
