var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Container',{key:_vm.groupId,attrs:{"title":_vm.groupDetail ? _vm.groupDetail.name : undefined,"headerAlign":"center","breadcrumbs":_vm.breadcrumbs},scopedSlots:_vm._u([(_vm.groupDetail)?{key:"header",fn:function(){return [(_vm.groupDetail)?[_c('div',[(_vm.isUserAllowedToViewBasicDetails)?_c('div',{class:_vm.$style.titleAndLogoContainer},[(_vm.groupLogoUrl)?_c('div',{class:_vm.$style.groupLogoImageContainer,style:({
              backgroundImage: ("url(" + _vm.groupLogoUrl + ")")
            })}):_vm._e(),_c('h1',[_vm._v(" "+_vm._s(_vm.groupDetail.name)+" "),(_vm.isProtected)?_c('i',{staticClass:"fa fa-lock"}):_vm._e()])]):_vm._e(),_c('div',{staticClass:"is-flex",class:_vm.$style.headerData},[(_vm.isUserAllowedToViewBasicDetails)?_c('div',[_c('i',{staticClass:"mdi mdi-account-group"}),_vm._v(" "+_vm._s(_vm.subGroups ? _vm.subGroups.totalItems : 0)+" Subgroup(s) ")]):_vm._e(),(_vm.isUserAllowedToViewBasicDetails)?_c('div',[_c('i',{staticClass:"mdi mdi-account"}),_vm._v(" "+_vm._s(_vm.membersCount)+" Member(s) ")]):_vm._e(),(_vm.isProtected)?_c('div',{class:_vm.$style.protectedTypeStyle},[_c('i',{staticClass:"fa fa-lock"}),_vm._v(" "+_vm._s(_vm.protectedTypeName)+" ")]):_vm._e(),(
              _vm.groupProductName &&
              _vm.groupDetail &&
              _vm.isUserAllowed(
                _vm.PermissionsMatrixActionsEnum.READ,
                'group_administration-groups-read_groups-read_license',
                true
              )
            )?_c('div',[_c('i',{staticClass:"mdi mdi-lock-open-outline"}),_vm._v(" Access to "),_c('router-link',{attrs:{"to":{
                name: 'admin-group-management',
                query: {
                  tab: 'License',
                  licenseId: _vm.licenseId
                }
              }}},[_vm._v(_vm._s(_vm.groupProductName)+" ")]),_vm._v(" ("+_vm._s(_vm.licenseSeats)+" seats) "+_vm._s(_vm.licenseStatus === false ? '( Inactive )' : '')+" ")],1):_vm._e()])]),_c('div',{class:_vm.$style.buttonContainer},[(
            _vm.canCreateSubGroup &&
            _vm.isUserAllowed(
              _vm.PermissionsMatrixActionsEnum.CREATE,
              'group_administration-groups-create_groups-create_subgroup',
              true
            )
          )?_c('router-link',{staticClass:"button is-primary",attrs:{"to":{
            name: 'admin-create-subgroup',
            params: {
              parentGroupId: _vm.groupId,
              isProtected: _vm.isProtected
            }
          }}},[_vm._v(" Create Subgroup ")]):_vm._e(),(
            _vm.isUserAllowed(
              _vm.PermissionsMatrixActionsEnum.UPDATE,
              'group_administration-groups-update_groups'
            )
          )?_c('router-link',{staticClass:"button is-primary is-outlined",class:_vm.$style.btnSettings,attrs:{"to":{ name: 'admin-group-settings' }}},[_c('i',{staticClass:"mdi mdi-settings"})]):_vm._e()],1)]:_vm._e()]},proxy:true}:null],null,true)},[(
      _vm.isUserAllowed(
        _vm.PermissionsMatrixActionsEnum.READ,
        'group_administration-groups-read_groups-read_groups_attributes',
        true
      )
    )?_c('GroupLevelAttributeView',{attrs:{"groupId":_vm.groupId},on:{"groupTitleAndLogo":_vm.handleTitleAndLogo}}):_vm._e(),(
      _vm.groupDetail &&
      _vm.subGroups &&
      _vm.subGroups.items.length &&
      _vm.isUserAllowed(
        _vm.PermissionsMatrixActionsEnum.READ,
        'group_administration-groups-read_groups-read_subgroups',
        true
      )
    )?[(_vm.hasMultiLvlSubGroups)?_c('BtnViewAll',{class:_vm.$style.btnViewAll,on:{"expand":function($event){_vm.allGroupsExpanded = true},"collapse":function($event){_vm.allGroupsExpanded = false}}}):_vm._e(),_c('BasePaginatorHoc',{attrs:{"component":_vm.groupList,"items":_vm.subGroups.items,"firstLvl":_vm.groupDetail.nlevel + 1,"allGroupsExpanded":_vm.allGroupsExpanded,"page":_vm.page,"perPage":_vm.perPage,"totalCount":_vm.subGroups ? _vm.subGroups.totalItems : 0,"sortColumn":_vm.sortColumn,"sortOrder":_vm.sortOrder},on:{"update:page":function($event){_vm.page=$event},"update:perPage":function($event){_vm.perPage=$event},"update:per-page":function($event){_vm.perPage=$event},"sort":_vm.handleSort,"paginate":_vm.handlePaginator}})]:_vm._e(),(
      _vm.groupDetail &&
      _vm.isUserAllowed(
        _vm.PermissionsMatrixActionsEnum.READ,
        'group_administration-groups-read_groups-read_members-read_members_list',
        true
      )
    )?_c('MemberSection',{key:_vm.memberSectionKey,attrs:{"parentGroupAttributeSpecs":_vm.parentGroupDetailByChildGroupId
        ? _vm.parentGroupDetailByChildGroupId.groupUserAttributeSpecs
        : [],"parentGroupMembers":_vm.parentGroupDetailByChildGroupId
        ? _vm.parentGroupDetailByChildGroupId.members
        : [],"attributeSpecs":_vm.allAttributeSpecs,"allowedEmailDomains":_vm.allowedEmailDomains,"groupTypeName":_vm.groupDetail.types[0],"group-exceptions":_vm.groupExceptions},on:{"update:allowedEmailDomains":function($event){_vm.allowedEmailDomains=$event},"update:allowed-email-domains":function($event){_vm.allowedEmailDomains=$event},"addMultipleMembers":_vm.handleAddMultipleMembers,"addFromParent":_vm.handleAddFromParent,"addExistingMembers":_vm.handleAddExistingMembers,"addMember":_vm.handleAddMember,"editMember":_vm.handleEditMember,"deleteMember":_vm.handleDeleteMember}}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }