var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"columns"},[_c('div',{staticClass:"column",class:_vm.$style.groupMemberListContainer},[(_vm.showMemberLabel)?_c('ValidationProvider',{attrs:{"rules":{ required: false },"name":"Members"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{staticClass:"m-t-2",attrs:{"label":_vm.membersData ? ("Members (" + (_vm.membersData.length) + ")") : "Members","type":errors.length ? 'is-danger' : undefined,"message":errors[0] || ''}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.membersData),expression:"membersData"}],attrs:{"type":"hidden"},domProps:{"value":(_vm.membersData)},on:{"input":function($event){if($event.target.composing){ return; }_vm.membersData=$event.target.value}}})])]}}],null,false,4125359364)}):_vm._e(),(_vm.errorNotification)?_c('b-notification',{attrs:{"type":"has-background-danger-light","role":"alert","closable":false}},[_vm._v(" "+_vm._s(_vm.errorNotification)+" ")]):_vm._e(),(
        _vm.syncedMembersData &&
        _vm.syncedMembersData.length &&
        _vm.isUserAllowed(
          _vm.PermissionsMatrixActionsEnum.UPDATE,
          'group_administration-groups-update_groups-update_members-update_member_details',
          true
        )
      )?_c('EditableMemberTable',_vm._g({attrs:{"fileName":_vm.membersFile && _vm.membersFile.name ? _vm.membersFile.name : '',"membersData":_vm.syncedMembersData,"notify":_vm.syncedNotify,"userAttributes":_vm.userAttributes,"allowedEmailDomains":_vm.allowedEmailDomains},on:{"update:membersData":function($event){_vm.syncedMembersData=$event},"update:members-data":function($event){_vm.syncedMembersData=$event},"update:notify":function($event){_vm.syncedNotify=$event},"update:allowedEmailDomains":function($event){_vm.allowedEmailDomains=$event},"update:allowed-email-domains":function($event){_vm.allowedEmailDomains=$event},"clear":function($event){return _vm.$emit('clear')}}},_vm.$listeners)):(
        _vm.isUserAllowed(
          _vm.PermissionsMatrixActionsEnum.UPDATE,
          'group_administration-groups-update_groups-update_members-create_members-create_new_members'
        )
      )?[_c('GroupCsvImportInfo',{attrs:{"userAttributes":_vm.userAttributes,"shouldDownloadExistingMemberInCsv":_vm.shouldDownloadExistingMemberInCsv,"membersData":_vm.membersData,"csvFileName":_vm.csvFileName}}),_c('FileUpload',{attrs:{"accept":".xlsx,.csv"},model:{value:(_vm.syncedMembersFile),callback:function ($$v) {_vm.syncedMembersFile=$$v},expression:"syncedMembersFile"}})]:_vm._e()],2)])}
var staticRenderFns = []

export { render, staticRenderFns }