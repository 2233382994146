var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(validator){
var _obj;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return (function () {
        validator.validate().then(function (validated) {
          if (validated === false) { return; }
          _vm.handleSubmit();
        });
      })($event)}}},[_c('div',{staticClass:"modal-card",staticStyle:{"width":"720px"}},[_c('b-loading',{attrs:{"is-full-page":false,"active":_vm.isLoading}}),_c('header',{staticClass:"modal-card-head"},[_c('p',{staticClass:"modal-card-title",class:_vm.$style.modalCardTitle},[(_vm.isEditing)?[_vm._v(" Edit License ")]:[_vm._v(" Create License ")]],2),_c('button',{staticClass:"close-icon",attrs:{"type":"button"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.$emit('close')}}},[_c('i',{staticClass:"mdi mdi-close"})])]),_c('section',{staticClass:"modal-card-body",class:( _obj = {}, _obj[_vm.$style.modalCardBody__loadingOptions] = _vm.isLoading, _obj )},[(_vm.id)?_c('input',{attrs:{"name":"id","type":"hidden"},domProps:{"value":_vm.id}}):_vm._e(),_c('ValidationProvider',{attrs:{"rules":"required","name":"Name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Name","type":errors.length ? 'is-danger' : undefined,"message":errors[0] || ''}},[_c('b-input',{model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required","name":"Product"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Product","type":errors.length ? 'is-danger' : undefined,"message":errors[0] || ''}},[_c('b-select',{attrs:{"expanded":"","disabled":_vm.isEditing},on:{"input":_vm.watchFormValues},model:{value:(_vm.form.productId),callback:function ($$v) {_vm.$set(_vm.form, "productId", $$v)},expression:"form.productId"}},_vm._l((_vm.groupProductsOptions),function(groupProduct){return _c('option',{key:groupProduct.id,domProps:{"value":groupProduct.id}},[_vm._v(" "+_vm._s(groupProduct.name)+" ")])}),0)],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"rules":"required","name":"Group"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Group","type":errors.length ? 'is-danger' : undefined,"message":errors[0] || ''}},[_c('treeselect',{ref:"treeselect",attrs:{"multiple":false,"options":_vm.groupOptions,"maxHeight":160,"default-expand-level":1,"loadingText":"Searching for groups...","async":true,"load-options":_vm.loadOptions,"flat":true,"disabled":_vm.isEditing},model:{value:(_vm.form.groupId),callback:function ($$v) {_vm.$set(_vm.form, "groupId", $$v)},expression:"form.groupId"}})],1)]}}],null,true)}),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('ValidationProvider',{attrs:{"rules":"required","name":"Period"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Period","type":errors.length ? 'is-danger' : undefined,"message":errors[0] || ''}},[_c('b-datepicker',{ref:"datepicker",attrs:{"range":"","years-range":[-1, 30],"icon-pack":"mdi","icon":"calendar"},nativeOn:{"click":function($event){return _vm.handleDatePickerToggle($event)}},model:{value:(_vm.form.dates),callback:function ($$v) {_vm.$set(_vm.form, "dates", $$v)},expression:"form.dates"}})],1)]}}],null,true)})],1),_c('div',{staticClass:"column"},[_c('ValidationProvider',{attrs:{"rules":{
                required: true,
                min_value: _vm.minSeats,
                max_value: 10000
              },"name":"Seats"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-field',{attrs:{"label":_vm.minSeats ? ("Seats (Min. " + _vm.minSeats + ")") : "Seats","type":errors.length ? 'is-danger' : undefined,"message":errors[0] || ''}},[_c('b-input',{key:_vm.minSeats,attrs:{"type":"number","min":_vm.minSeats},model:{value:(_vm.form.seats),callback:function ($$v) {_vm.$set(_vm.form, "seats", $$v)},expression:"form.seats"}})],1)]}}],null,true)})],1)]),(!_vm.isEditing)?_c('b-checkbox',{staticClass:"m-t-1 m-b-1",model:{value:(_vm.form.notify),callback:function ($$v) {_vm.$set(_vm.form, "notify", $$v)},expression:"form.notify"}},[_c('span',[_vm._v(" Notify members ")])]):_vm._e()],1),_c('footer',{staticClass:"modal-card-foot"},[_c('button',{staticClass:"button is-primary",class:{ 'is-loading': _vm.submitLoading },attrs:{"type":"submit","disabled":_vm.isSubmitButtonDisabled || _vm.isLoading}},[(_vm.isEditing)?[_vm._v(" Save ")]:[_vm._v(" Create License ")]],2)])],1)])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }