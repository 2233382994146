







































































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import BasePaginatorHoc from '@/components/base/BasePaginatorHoc.vue';
import { Debounce } from '@/jbi-shared/util/debounce.vue-decorator';
import Dropdown from '@/components/base/Dropdown.vue';
import { MyjbiGroupUserAttributeSpec } from '../../../jbi-shared/types/myjbi-group.types';
import { FilteredGroupMemberParamsPayload } from '../../../store/modules/admin/types/group.types';
import ListDropdownComponent from '@/views/AdminCreateGroup/components/UserLevelAttributes/ListDropdownComponent.vue';

@Component({
  components: {
    BasePaginatorHoc,
    Dropdown,
    ListDropdownComponent
  }
})
export default class MemberSearch extends Vue {
  @Prop() attributeSpecs!: MyjbiGroupUserAttributeSpec[];
  @Prop(Object) public filteredMemberParams!: FilteredGroupMemberParamsPayload;

  get userAttributeSpecs() {
    return this.attributeSpecs.filter(
      (userAttr) =>
        userAttr.groupUserAttribute.groupUserAttributeType.type !== 'image'
    );
  }

  get filteredGroupMemberParams() {
    const filteredParams: { [s: string]: any } = this.filteredMemberParams;
    this.attributeSpecs.forEach((attr) => {
      if (attr.groupUserAttribute.groupUserAttributeType.type === 'date') {
        filteredParams[attr.groupUserAttribute.slug] = filteredParams[
          attr.groupUserAttribute.slug
        ]
          ? new Date(filteredParams[attr.groupUserAttribute.slug])
          : null;
      }
    });

    return filteredParams;
  }

  isSingleList(attributeSpec: MyjbiGroupUserAttributeSpec): boolean {
    return (
      attributeSpec.groupUserAttribute.groupUserAttributeType.type === 'list' &&
      attributeSpec.groupUserAttribute.option!.multiSelect === false
    );
  }

  isMultipleList(attributeSpec: MyjbiGroupUserAttributeSpec): boolean {
    return (
      attributeSpec.groupUserAttribute.groupUserAttributeType.type === 'list' &&
      attributeSpec.groupUserAttribute.option!.multiSelect === true
    );
  }

  getListValueFromAttributeSpec(
    attributeSpec: MyjbiGroupUserAttributeSpec
  ): string[] {
    return attributeSpec.groupUserAttribute.option!.value;
  }

  updateFilteredParam(
    selectedListValues: string,
    attributeSpec: MyjbiGroupUserAttributeSpec
  ) {
    /**
     * Using $set to update filteredGroupMemberParams (computed) to make sure
     * that the change is detected by Vue reactivity system
     */
    this.$set(
      this.filteredGroupMemberParams,
      attributeSpec.groupUserAttribute.slug,
      selectedListValues
    );
  }

  @Watch('filteredGroupMemberParams', { deep: true })
  @Debounce(600)
  public onChange() {
    this.$emit('input', this.filteredGroupMemberParams);
    this.$emit('select', this.filteredGroupMemberParams);
  }

  public resetFilter() {
    return this.$emit('resetFilter');
  }
}
