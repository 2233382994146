var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseTable',{class:_vm.$style.memberListContainer,scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('tr',[_c('th',{attrs:{"align":"left"}},[_c('DropdownMultiselect',{attrs:{"options":_vm.attributes,"fixedOptions":[],"position":"is-bottom-right","triggers":['hover']},scopedSlots:_vm._u([{key:"customTrigger",fn:function(){return [_c('span',{staticClass:"icon has-text-primary",attrs:{"slot":"trigger","role":"button"},slot:"trigger"},[_c('i',{staticClass:"mdi mdi-table-of-contents is-size-3"})])]},proxy:true}]),model:{value:(_vm.selectedAttributeSpecs),callback:function ($$v) {_vm.selectedAttributeSpecs=$$v},expression:"selectedAttributeSpecs"}})],1),_vm._l((_vm.selectedAttributeSpecs),function(attributeSpec,index){return _c('div',{key:attributeSpec.id,class:_vm.$style.draggableTableHeader,attrs:{"column":attributeSpec['groupUserAttribute']['slug'],"draggable":""},on:{"dragstart":function($event){return _vm.onDragStart(
            $event,
            attributeSpec['groupUserAttribute']['slug'],
            index
          )},"drop":function($event){return _vm.onDrop($event, attributeSpec['groupUserAttribute']['slug'], index)},"dragover":function($event){$event.preventDefault();},"dragenter":function($event){$event.preventDefault();}}},[(
            attributeSpec['groupUserAttribute']['groupUserAttributeType'][
              'type'
            ] === 'image'
          )?_c('th',{class:_vm.$style.noWrap,attrs:{"column":attributeSpec['groupUserAttribute']['slug'],"sortOrder":_vm.getSortOrderOfColumn(attributeSpec['groupUserAttribute']['slug']),"showArrow":true,"sort":true}},[_vm._v(" "+_vm._s(attributeSpec.groupUserAttribute.name)+" ")]):_c('SortableTableHeader',{class:_vm.$style.noWrap,attrs:{"column":attributeSpec['groupUserAttribute']['slug'],"sortOrder":_vm.getSortOrderOfColumn(attributeSpec['groupUserAttribute']['slug']),"showArrow":true,"sort":true},on:{"sort":_vm.onClickHeaderColumn}},[_vm._v(" "+_vm._s(attributeSpec.groupUserAttribute.name)+" ")])],1)})],2)]},proxy:true},(_vm.getMembersSuccess)?{key:"body",fn:function(){return _vm._l((_vm.items),function(member){return _c('tr',{key:member.userId},[_c('td',{attrs:{"align":"left"}},[_c('TableActionDropdown',{attrs:{"position":'is-bottom-right'}},[_c('b-dropdown-item',{attrs:{"aria-role":"listitem","disabled":""},on:{"click":function () {}}},[_vm._v(" Appoint as Administrator ")]),(
              _vm.isUserAllowed(
                _vm.PermissionsMatrixActionsEnum.READ,
                'group_administration-groups-read_groups-read_members'
              ) ||
              _vm.isUserAllowed(
                _vm.PermissionsMatrixActionsEnum.UPDATE,
                'group_administration-groups-update_groups-update_members-update_member_details'
              )
            )?_c('b-dropdown-item',{attrs:{"aria-role":"listitem"},on:{"click":function($event){return _vm.handleEditMember(member)}}},[_vm._v(" View/Edit Member Details ")]):_vm._e(),_c('b-dropdown-item',{attrs:{"aria-role":"listitem","disabled":member.isActiveUser,"title":member.isActiveUser ? "Already a member on MYJBI" : undefined},on:{"click":function($event){return _vm.handleResendEmailNotification(member)}}},[_vm._v(" Resend invitation link ")]),(
              _vm.isUserAllowed(
                _vm.PermissionsMatrixActionsEnum.DELETE,
                'group_administration-groups-update_groups-update_members-delete_members',
                true
              )
            )?_c('b-dropdown-item',{staticClass:"has-text-danger",attrs:{"aria-role":"listitem"},on:{"click":function($event){return _vm.handleDeleteMember(member)}}},[_vm._v(" Remove from this Group ")]):_vm._e()],1)],1),_vm._l((_vm.selectedAttributeSpecs),function(attributeSpec){return _c('td',{key:attributeSpec.id},[(
            attributeSpec['groupUserAttribute']['groupUserAttributeType'][
              'type'
            ] === 'image'
          )?_c('span',{class:[
            _vm.$style.imageIconBox,
            !member.attributes[attributeSpec.groupUserAttribute.slug]
              ? _vm.$style.imageIconBoxExtraPadding
              : ''
          ]},[(member.attributes[attributeSpec.groupUserAttribute.slug])?_c('img',{class:_vm.$style.imageIcon,attrs:{"src":member.attributes[attributeSpec.groupUserAttribute.slug]}}):_c('b-icon',{class:_vm.$style.imageIcon,attrs:{"icon":"image"}})],1):(
            attributeSpec['groupUserAttribute']['groupUserAttributeType'][
              'type'
            ] === 'date'
          )?_c('p',[_vm._v(" "+_vm._s(member.attributes[attributeSpec.groupUserAttribute.slug] ? _vm.formatedDate( member.attributes[attributeSpec.groupUserAttribute.slug] ) : '-')+" ")]):(
            attributeSpec['groupUserAttribute']['groupUserAttributeType'][
              'type'
            ] === 'text area'
          )?_c('p',{class:_vm.$style.clampedContent},[_vm._v(" "+_vm._s(member.attributes[attributeSpec.groupUserAttribute.slug] ? _vm.renderTextAreaContent( member.attributes[attributeSpec.groupUserAttribute.slug] ) : '-')+" ")]):_c('p',[_vm._v(" "+_vm._s(member.attributes[attributeSpec.groupUserAttribute.slug] || '-')+" ")])])})],2)})},proxy:true}:{key:"body",fn:function(){return [_c('tr',[_c('td',{staticClass:"is-paddingless",attrs:{"colspan":"100%"}},[_c('BaseLoading')],1)])]},proxy:true},{key:"footer",fn:function(){return [_c('tr',[_c('td',{staticClass:"is-paddingless",attrs:{"colspan":"100%"}},[(_vm.items && _vm.items.length > 0)?_c('BasePagination',{attrs:{"page":_vm.page,"perPage":_vm.perPage,"totalNumberOfPage":_vm.totalNumberOfPage,"totalCount":_vm.totalCount,"isFirstPage":_vm.isFirstPage,"isLastPage":_vm.isLastPage,"startItemIndex":_vm.startItemIndex,"endItemIndex":_vm.endItemIndex},on:{"update:page":function($event){_vm.page=$event},"update:perPage":function($event){_vm.perPage=$event},"update:per-page":function($event){_vm.perPage=$event}}}):_c('p',{staticClass:"p-2 has-text-centered"},[_vm._v("No Data Found")])],1)])]},proxy:true}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }