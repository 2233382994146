


















































































































































































































import { Component, Prop, Watch } from 'vue-property-decorator';
import { State } from 'vuex-class';
import { cloneDeep as _cloneDeep, get as _get, uniqBy } from 'lodash';
import BasePagination from '@/components/base/BasePagination.vue';
import BaseLoading from '@/components/base/BaseLoading.vue';
import { RootState } from '@/store/store';
import { mixins } from 'vue-class-component';
import { PaginationMixin } from '@/components/base/pagination.mixin';
import { User } from '@/store/modules/admin/types/admin.types';
import BaseTable from '@/components/BaseTable.vue';
import {
  isValidGroupUserAttributeValue,
  MemberObject
} from '../../../utils/group.util';
import { UserWithExtraAttributes } from '@/store/modules/admin/types/admin.types';
import { MyjbiGroupUserAttributeSpec } from '../../../jbi-shared/types/myjbi-group.types';
import ImageUploadComponent from './UserLevelAttributes/ImageUploadComponent.vue';
import ListDropdownComponent from './UserLevelAttributes/ListDropdownComponent.vue';
import RichTextEditorForm from './UserLevelAttributes/RichTextEditorForm.vue';
import { getHtmlContent } from '@/jbi-shared/util/group-level-attributes.util';

@Component({
  components: {
    BasePagination,
    BaseTable,
    BaseLoading,
    ImageUploadComponent,
    ListDropdownComponent
  }
})
export default class ExistingUsersList extends mixins(PaginationMixin) {
  @Prop() public items!: User[];
  @Prop() userAttributes!: MyjbiGroupUserAttributeSpec[];
  @Prop(Number) public currentPage!: number;
  @Prop(Number) public totalNumberOfPage!: number;
  @Prop(Number) public totalCount!: number;
  @Prop(Boolean) public isFirstPage!: boolean;
  @Prop(Boolean) public isLastPage!: boolean;
  @Prop(Number) public startItemIndex!: number;
  @Prop(Number) public endItemIndex!: number;
  @Prop() public selectedUsers!: User[];
  @Prop(Boolean) public isNewGroup!: boolean;
  @Prop(Number) public textEditorRenderKey!: number;
  @Prop(Number) public listRenderKey!: number;

  tempItems: UserWithExtraAttributes[] = [];

  @State(({ admin }: RootState) => admin.apiState.getUsers.success)
  public getUsersSuccess!: boolean;

  get selectedItems(): User[] {
    return this.selectedUsers;
  }

  set selectedItems(value: User[]) {
    this.$emit('update:selectedUsers', value);
  }

  get filteredAttributes(): MyjbiGroupUserAttributeSpec[] {
    return this.isNewGroup
      ? this.userAttributes.filter((userAttr) => {
          return userAttr.isDefault;
        })
      : this.userAttributes;
  }

  getImage(
    imageUrl: string,
    index: number,
    attributeSpec: MyjbiGroupUserAttributeSpec
  ) {
    this.tempItems[index][attributeSpec.groupUserAttribute.slug] = imageUrl;
  }

  getListValue(
    listValues: string,
    index: number,
    attributeSpec: MyjbiGroupUserAttributeSpec
  ) {
    this.tempItems[index][attributeSpec.groupUserAttribute.slug] = listValues;
    this.$emit('updateListRenderKey');
  }

  renderTextAreaContent(attributeValue: string): string {
    return getHtmlContent(attributeValue || '');
  }

  openRichTextEditor(
    memberIndex: number,
    required: boolean,
    attributeLabel: string,
    attributeSlug: string
  ): void {
    const attributeValue: string =
      this.tempItems[memberIndex][attributeSlug] || '';
    const email: string = this.tempItems[memberIndex].email || '';

    this.$buefy.modal.open({
      parent: this,
      component: RichTextEditorForm,
      hasModalCard: true,
      trapFocus: true,
      fullScreen: false,
      canCancel: true,
      props: {
        htmlString: attributeValue,
        label: attributeLabel,
        email,
        required
      },
      events: {
        updateHtml: (updatedHtml: string) => {
          let currentMemberIndex: number = -1;

          this.selectedItems.forEach((selectedMember, selectedMemberIndex) => {
            if (selectedMember.email === email) {
              currentMemberIndex = selectedMemberIndex;
            }
          });

          this.$emit(
            'updateTextArea',
            currentMemberIndex,
            attributeSlug,
            updatedHtml
          );
        }
      }
    });
  }

  public isValid(
    user: User & MemberObject,
    attributeSpec: MyjbiGroupUserAttributeSpec
  ) {
    const isSelected = this.selectedItems.includes(user);
    let slug: string = attributeSpec.groupUserAttribute.slug;
    switch (attributeSpec.groupUserAttribute.slug) {
      case 'first-name':
        slug = 'firstName';
        break;
      case 'last-name':
        slug = 'lastName';
        break;
      default:
        slug = slug;
    }

    if (isSelected && !this.isNewGroup) {
      return isValidGroupUserAttributeValue(attributeSpec, user[slug]);
    }
    return { isValid: true, errorMessage: null };
  }

  public mounted() {
    this.tempItems = _cloneDeep(this.items);
  }
}
