


























































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import BasePaginatorHoc from '@/components/base/BasePaginatorHoc.vue';
import {
  isValidGroupUserAttributeValue,
  MemberObject
} from '../../../utils/group.util';
import BasePagination from '@/components/base/BasePagination.vue';
import BaseTable from '@/components/BaseTable.vue';
import { Action, State } from 'vuex-class';
import {
  GetInvitedUserPayload,
  User,
  UserWithExtraAttributes
} from '@/store/modules/admin/types/admin.types';
import { RootState } from '@/store/store';
import { Pagination } from 'nestjs-typeorm-paginate';
import {
  get as _get,
  filter as _filter,
  keyBy as _keyBy,
  isEmpty as _isEmpty,
  forEach as _forEach,
  uniq
} from 'lodash';
import ExistingUserList from './ExistingUserList.vue';
import { Debounce } from '@/jbi-shared/util/debounce.vue-decorator';
import { ToastProgrammatic } from 'buefy';
import {
  isEmail,
  validateEmailDomain
} from '@/jbi-shared/util/validate-email-domains.util';
import { MyjbiGroupUserAttributeSpec } from '../../../jbi-shared/types/myjbi-group.types';

@Component({
  components: {
    BaseTable,
    BasePagination,
    BasePaginatorHoc
  }
})
export default class AddMembersFromListModal extends Vue {
  @Prop() userAttributes!: MyjbiGroupUserAttributeSpec[];
  @Prop() existingMembers!: MemberObject[];
  @Prop({ type: Boolean, default: false }) showNotify!: boolean;
  @Prop({ type: Boolean, default: false }) newGroup!: boolean;
  @Prop() allowedEmailDomains!: string[];
  @Prop({ type: Boolean, default: false })
  checkForMissingAttributeValue!: boolean;

  public notify: boolean = false;
  public isMissingRequiredValue: boolean = false;

  public perPage = 50;
  public page = 1;
  public searchTerm = '';
  public selectedUsers: UserWithExtraAttributes[] = [];
  public existingUserListKey: number = Math.floor(Math.random() * 999);
  public textEditorRenderKey: number = Math.floor(Math.random() * 999);
  public listRenderKey: number = Math.floor(Math.random() * 999);

  @Action('admin/getUsers')
  public getUsers!: (params: GetInvitedUserPayload) => void;

  @State(({ admin }: RootState) => admin.userDetails)
  public userList!: Pagination<User>;

  get ExistingUserList() {
    return ExistingUserList;
  }

  get users() {
    const attributes = this.userAttributes?.filter(
      (userAttr) => !userAttr.isDefault
    );
    const users = this.userList?.items ? this.userList?.items : [];
    const userList = users.map((user) => {
      return { ...user, attributes: this.userAttributes };
    });
    return {
      items: userList,
      totalItems: _get(this.userList, 'totalItems', 0)
    };
  }

  get filteredAttributes(): MyjbiGroupUserAttributeSpec[] {
    const filteredAttributes = this.userAttributes?.filter((userAttr) => {
      return !userAttr.isDefault;
    });
    return filteredAttributes;
  }

  public handlePaginator() {
    const params = {
      email: this.searchTerm,
      limit: this.perPage,
      page: this.page
    };

    return this.getUsers(params);
  }

  updateTextAreaContent(
    selectedUserIndex: number,
    attributeSlug: string,
    textAreaValue: string
  ) {
    if (this.selectedUsers[selectedUserIndex]) {
      this.isMissingRequiredValue = !this.validateUserAttributeValue();
      this.selectedUsers[selectedUserIndex][attributeSlug] = textAreaValue;
    }
    this.textEditorRenderKey += 1;
  }

  updateListRenderKey(): void {
    this.isMissingRequiredValue = !this.validateUserAttributeValue();
    this.listRenderKey += 1;
  }

  public handleAdd() {
    const data: MemberObject[] = this.selectedUsers.map((user: any) => {
      const attributes = this.userAttributes?.map((attr) => {
        let { slug } = attr.groupUserAttribute;
        switch (slug) {
          case 'first-name':
            slug = 'firstName';
            break;
          case 'last-name':
            slug = 'lastName';
            break;
          default:
            slug = slug;
        }
        const { isValid, errorMessage } = isValidGroupUserAttributeValue(
          attr,
          user[slug],
          this.allowedEmailDomains
        );
        let value = null;
        if (slug in user) {
          value = user[slug] ? user[slug] : null;
        }
        return {
          [`${attr.groupUserAttribute.slug}`]: {
            value,
            isRequired: attr.required ? attr.required : false,
            isValid,
            errorMessage
          }
        };
      });

      const getAttribute = attributes?.reduce((r, a) => {
        return Object.assign(r, a);
      }, {});

      return { ...getAttribute };
    });

    const requiredAttributeArray = this.userAttributes
      ? this.userAttributes
          .filter((userAttr) => {
            return userAttr.required;
          })
          .map((userAttr) => {
            return userAttr.groupUserAttribute.slug; // return only the slug into an array to compare
          })
      : [];
    let emailErrorMessage: string | null = null;
    const validateRequiredAttributes: boolean = requiredAttributeArray?.every(
      (attribute) => {
        return data.every((membersData: MemberObject) => {
          if (attribute === 'email' && !membersData[attribute].isValid) {
            emailErrorMessage = membersData[attribute].errorMessage;
          }
          return membersData[attribute].isValid;
        });
      }
    );
    if (!validateRequiredAttributes && !this.newGroup) {
      return ToastProgrammatic.open({
        queue: true,
        type: 'is-danger',
        position: 'is-top',
        message: emailErrorMessage
          ? 'Selected users do not conform to email domain restriction'
          : 'Please fill in valid values.',
        duration: 5000
      });
    }

    if (this.allowedEmailDomains && this.allowedEmailDomains.length > 0) {
      const isDomainValid: boolean = data.every((membersData) => {
        return (
          isEmail(membersData.email.value) &&
          validateEmailDomain(membersData.email.value, this.allowedEmailDomains)
        );
      });
      if (!isDomainValid) {
        return ToastProgrammatic.open({
          queue: true,
          type: 'is-danger',
          position: 'is-top',
          message: 'Selected users do not conform to email domain restriction',
          duration: 5000
        });
      }
    }

    const emails = [...this.existingMembers, ...data].map((membersData) => {
      return membersData.email.value !== undefined
        ? membersData.email.value.trim()
        : membersData.email.trim();
    });
    if (uniq(emails).length !== emails.length) {
      return ToastProgrammatic.open({
        queue: true,
        type: 'is-danger',
        position: 'is-top',
        message: 'Duplicated email addresses found in selected user list',
        duration: 5000
      });
    }

    this.$emit('add', { data, notify: this.notify });

    // @ts-ignore
    this.$parent.close();
  }

  validateUserAttributeValue(): boolean {
    if (!this.selectedUsers.length) {
      return true;
    }
    // Find required attribute value which are invalid
    return !this.selectedUsers.find(
      (userWithAttribute: UserWithExtraAttributes) => {
        return userWithAttribute.attributes.find(
          (attribute: MyjbiGroupUserAttributeSpec) => {
            // User cannot update default attributes in 'Members' table.
            // So, return false directly if the attribute is 'default'.
            if (attribute.isDefault) {
              return false;
            }
            const value: string =
              userWithAttribute[attribute.groupUserAttribute.slug];
            const { isValid } = isValidGroupUserAttributeValue(
              attribute,
              value
            );
            return !isValid;
          }
        );
      }
    );
  }

  @Watch('selectedUsers', { deep: true })
  @Debounce(200)
  onSelectedUsersChange() {
    this.isMissingRequiredValue = !this.validateUserAttributeValue();
  }

  @Watch('searchTerm')
  @Debounce(500)
  public onSearchTermChanged() {
    this.page = 1;
    this.perPage = 50;
    this.handlePaginator();
  }

  @Watch('userList')
  public onUserListChanged() {
    this.existingUserListKey += 1;
  }

  public mounted() {
    if (this.showNotify) {
      this.notify = false;
    }
    this.handlePaginator();
  }
}
