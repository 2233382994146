



































































































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { JAAS_APP } from '@/jbi-shared/types/jaas-app.types';
import { RootState } from '@/store/store';
import {
  CreateGroupLicenseRequestPayload,
  UpdateGroupLicenseRequestPayload
} from '@/store/modules/admin/types/group-license.types';
import dayjs from 'dayjs';
import { pluckNewValue } from '@/jbi-shared/util/pluck.rx-operator';
import { filter, pluck } from 'rxjs/operators';
import { omit } from 'lodash';
import { Action } from 'vuex-class';
import {
  FilteredGroupPayload,
  GetGroupsResponsePayload,
  GetGroupStatsResponsePayload
} from '@/store/modules/admin/types/admin.types';
import { IPaginationOptions } from 'nestjs-typeorm-paginate';
import { GetGroupsProductResponsePayload } from '@/store/modules/admin/types/group-product.types';
import Treeselect from '@riophae/vue-treeselect';
import { ASYNC_SEARCH } from '@riophae/vue-treeselect';

interface TreeSelectStruct {
  id: number;
  label: string;
  children: TreeSelectStruct[];
  isDisabled?: boolean;
  hasLicense?: boolean;
}

interface FlattenedGroup {
  id: number;
  path: string;
  memberCount?: number;
  nlevel?: number;
  name?: string;
  licenseName?: string;
}

/*
 * This interface was based off of what can be found when inspecting
 * the component using Vue.js devtools. The shape is not complete but serves
 * as a way to traverse the component reference in a type-safe way.
 * */
export interface TreeSelectElement extends Vue {
  forest: {
    normalizedOptions: any[];
    nodeMap: {
      [nodeId: string]: {
        id: number;
        isBranch: boolean;
        isDisabled: boolean;
        isFallbackNode: boolean;
        isLeaf: boolean;
        isNew: boolean;
        isRootNode: boolean;
        label: string;
        level: number;
      };
    };
    checkedStateMap: any;
    selectedNodeIds: number[];
    selectedNodeMap: any;
  };
}

@Component({
  components: {
    ValidationObserver,
    ValidationProvider,
    Treeselect
  }
})
export default class LicenseFormModal extends Vue {
  @Prop(Number) id!: number;
  @Prop(String) name!: string;
  @Prop(String) startAt!: string;
  @Prop(String) endAt!: string;
  @Prop(Number) seats!: number;
  @Prop(Number) groupMemberCount!: number;
  @Prop(Number) groupId!: number;
  @Prop(Number) groupProductId!: number;
  @Prop(Boolean) isActive!: boolean;

  public isSubmitButtonDisabled: boolean = true;

  public form: Partial<
    CreateGroupLicenseRequestPayload | UpdateGroupLicenseRequestPayload
  > & {
    dates: Date[];
    groupId: number | string;
    productId: number;
    notify: boolean;
  } = {
    name: this.name || '',
    id: this.id,
    seats: this.seats,
    groupId: this.groupId,
    productId: this.groupProductId,
    dates: [],
    notify: false,
    isActive: this.isActive
  };

  nestedGroups: TreeSelectStruct[] = [];

  @Action('admin/getGroups')
  getGroups!: (
    options: FilteredGroupPayload
  ) => Promise<GetGroupsResponsePayload>;

  @Action('admin/getGroupProducts')
  getGroupProducts!: (
    options: IPaginationOptions
  ) => Promise<GetGroupsProductResponsePayload>;

  @Action('admin/getGroupStats')
  getGroupStats!: (
    isEditing?: boolean
  ) => Promise<GetGroupStatsResponsePayload>;

  get applications() {
    return [{ id: JAAS_APP.sumari, name: 'SUMARI' }];
  }

  get groupProductsOptions() {
    return (this.$store.state as RootState).admin.groupProducts?.items;
  }

  get groups() {
    return (this.$store.state as RootState).admin.groups;
  }

  get groupStats() {
    return (this.$store.state as RootState).admin.groupStats;
  }

  get groupOptions() {
    return this.nestedGroups;
  }

  binarySearchForIndex(array: TreeSelectStruct[], query: number): number {
    let start = 0;
    let end = array.length - 1;

    while (start <= end) {
      const middle = Math.floor((start + end) / 2);

      if (array[middle].id === query) {
        return middle;
      } else if (array[middle].id < query) {
        start = middle + 1;
      } else {
        end = middle - 1;
      }
    }

    return -1;
  }

  /*
   * Group license data is not a part of the response of /groups.
   * However, that data can be retrieved from groupStats.
   **/
  getLabelAndLicense(
    data: FlattenedGroup
  ): { label: string; license: boolean } {
    let label = `${data.name} (${data.memberCount} members)`;
    const licenseName =
      this.groupStats &&
      this.groupStats.find((group) => data.id === group.id)?.licenseName;
    if (licenseName !== undefined) {
      label = label + ` [License: ${licenseName}]`;
    }
    return { label, license: !!licenseName };
  }

  /*
   * Group data is pulled from GET /groups. Master groups and their
   * children are stored in a flat matter. The only way to determine if one
   * group is related to another is through the path string which traces the
   * group's lineage.
   *
   * The getNestedGroups function takes these flattened groups and nests them
   * according to their lineage.
   *
   * The nesting process in a nutshell:
   * - Sort flattened groups data in ascending order
   * - Because the data is sorted, master groups are made first
   * - If group is a sub (based on path), determine direct parent and traverse
   * - nestedGroups (this process is recursive as direct parent may itself be
   * - nested)
   * - Place sub inside of children array
   **/

  /*
   * TODO: Current approach is not scalable. License data is not a part of
   * /groups and, as such, they have to be retrieved from the groupStats
   * array. While this mapping operation is done client-side, a separate API
   * that bundles license data along with group data would be ideal.
   **/

  setNestedGroups(array: FlattenedGroup[]): void {
    const nestedGroups: TreeSelectStruct[] = [];
    for (const item of array) {
      const segmentedPath = item.path.split('.');
      // If item is a master group
      if (segmentedPath.length === 1) {
        const { label, license } = this.getLabelAndLicense(item);
        nestedGroups.push({
          id: item.id,
          label,
          children: [],
          isDisabled: true,
          hasLicense: license
        });
      } else {
        const masterGroupPath = segmentedPath[0];
        /*
         * Second to last item in an array is = array.length - 1 - 1
         **/
        const directParentPath = segmentedPath[segmentedPath.length - 2];
        const group =
          nestedGroups[
            this.binarySearchForIndex(
              nestedGroups,
              parseInt(masterGroupPath, 10)
            )
          ];

        if (group && directParentPath) {
          // group here is passed by reference
          this.nestGroups([group], parseInt(directParentPath, 10), item);
        }
      }
    }

    this.nestedGroups = nestedGroups;
  }

  nestGroups(
    masterGroup: TreeSelectStruct[],
    directParentPath: number,
    targetGroup: FlattenedGroup
  ): void {
    for (const item of masterGroup) {
      if (item.id === directParentPath) {
        const { label, license } = this.getLabelAndLicense(targetGroup);
        item.children.push({
          id: targetGroup.id,
          label,
          children: [],
          isDisabled: license
        });
      } else if (Array.isArray(item.children)) {
        this.nestGroups(item.children, directParentPath, targetGroup);
      }
    }
  }

  async loadOptions({
    action,
    searchQuery,
    callback
  }: {
    action: string;
    searchQuery: string;
    callback: (error: Error | null, parentNode: TreeSelectStruct[]) => void;
  }) {
    if (action === ASYNC_SEARCH) {
      await this.getGroups({
        page: 1,
        limit: 50,
        sortOrder: 'ASC',
        groupname: searchQuery
      });
      if (this.groups) {
        /*
         * Sorting is a crucial step. Master groups must be created before their
         * subgroups can be nested inside them.
         **/
        const sortedData = JSON.parse(JSON.stringify(this.groups.items)).sort(
          (a: FlattenedGroup, b: FlattenedGroup) => a.id - b.id
        );
        this.setNestedGroups(sortedData);
      }
      callback(null, this.nestedGroups);
    }
  }

  get minSeats() {
    return this.groupStats?.find(
      (g) =>
        g.id ===
        ((this.form as CreateGroupLicenseRequestPayload)?.groupId ||
          this.groupId)
    )?.memberCount;
  }

  get submitLoading() {
    return (
      (this.$store?.state as RootState).admin.apiState.createGroupLicense
        .loading ||
      (this.$store?.state as RootState).admin.apiState.updateGroupLicense
        .loading
    );
  }

  get isLoading() {
    return (
      (this.$store?.state as RootState).admin.apiState.getGroupStats.loading ||
      (this.$store?.state as RootState).admin.apiState.getGroupProducts.loading
    );
  }

  get isEditing() {
    // Editing would resolve as false if ID is 0. Unlikely situation but
    // catering for it anyway
    return Boolean(this.id);
  }

  async handleDatePickerToggle() {
    await this.$nextTick();
    const scroll = () =>
      ((this.$refs?.datepicker as Vue)?.$refs
        ?.dropdown as Vue)?.$el?.scrollIntoView();
    scroll();
  }

  handleSubmit() {
    let form = omit(this.form, 'dates');
    if (this.isEditing) {
      form = omit(form, 'groupId', 'productId');
    }
    this.$emit('submit', form);
  }

  initGroupDropdown(): void {
    if (this.groupStats) {
      const groupData = this.groupStats.find(
        (group) => group.id === this.groupId
      );
      if (groupData) {
        const { label } = this.getLabelAndLicense(groupData);
        (this.$refs.treeselect as TreeSelectElement).forest.nodeMap[
          this.groupId
        ].label = label;
      }
    }
  }

  mounted() {
    this.getGroupStats();

    this.getGroupProducts({
      page: 1,
      limit: Math.pow(10, 3)
    });

    this.$watchAsObservable('form', { deep: true })
      .pipe(pluckNewValue(), filter(Boolean), pluck('dates'), filter(Boolean))
      .subscribe((dates) => {
        const [startAt, endAt] = dates as Date[];
        this.form.startAt = dayjs(startAt).format('YYYY-MM-DD');
        this.form.endAt = dayjs(endAt).format('YYYY-MM-DD');
      });

    if (this.startAt && this.endAt) {
      this.form.dates = [new Date(this.startAt), new Date(this.endAt)];
    } else {
      this.form.dates = [];
    }
  }

  @Watch('form.name')
  @Watch('form.dates')
  @Watch('form.seats')
  @Watch('minSeats')
  public watchFormValues() {
    const formValues = JSON.parse(JSON.stringify(this.form));
    this.isSubmitButtonDisabled = !(
      this.form.name &&
      this.form.startAt &&
      this.form.endAt &&
      this.form.seats &&
      this.form.dates.length &&
      this.minSeats !== undefined &&
      this.form.seats >= this.minSeats &&
      formValues.productId !== undefined
    );
  }

  @Watch('groupStats')
  public watchGroupStats() {
    if (this.groupStats !== undefined) {
      if (this.isEditing) {
        this.initGroupDropdown();
      }
    }
  }
}
