






























































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { ToastProgrammatic as Toast } from 'buefy';
import { Action, State } from 'vuex-class';
import MemberList from '@/views/AdminViewGroup/components/MemberList.vue';
import MemberSearch from '../components/MemberSearch.vue';
import BasePaginatorHoc from '@/components/base/BasePaginatorHoc.vue';
import {
  MyjbiGroupDetail,
  MyjbiGroupMember
} from '@/jbi-shared/types/myjbi-group.types';
import { differenceBy, fromPairs } from 'lodash';
import Dropdown from '@/components/base/Dropdown.vue';
import SingleMemberModal from '@/views/AdminViewGroup/components/SingleMemberModal.vue';
import AddMultipleMembersModal from '@/views/AdminViewGroup/components/AddMultipleMembersModal.vue';
import { AddExistingMemberPayload, MemberObject } from '@/utils/group.util';
import AddFromParentModal from '@/views/AdminViewGroup/components/AddFromParentModal.vue';
import AddMembersFromListModal from '@/views/AdminCreateGroup/components/AddMembersFromListModal.vue';
import { ExportGroupMembersCsvPayload } from '@/store/modules/admin/types/admin.types';
import { RootState } from '@/store/store';
import { Pagination } from 'nestjs-typeorm-paginate';
import { FilteredGroupMemberParamsPayload } from '@/store/modules/admin/types/group.types';
import {
  PermissionsMatrixActionsEnum,
  ResourceExceptions
} from '@/store/modules/roles-and-permissions/types/roles-and-permissions.types';
import { isUserAllowed } from '@/utils/rbac.util';
import { EntityTypes } from '@/store/modules/module-tree/enums/module-tree.enums';

@Component({
  computed: {
    PermissionsMatrixActionsEnum() {
      return PermissionsMatrixActionsEnum;
    }
  },
  components: {
    BasePaginatorHoc,
    MemberList,
    MemberSearch,
    Dropdown,
    AddMembersFromListModal
  }
})
export default class MemberSection extends Vue {
  @Prop() attributeSpecs!: MyjbiGroupDetail['groupUserAttributeSpecs'];
  @Prop(Array) parentGroupMembers!: MyjbiGroupDetail['members'];
  @Prop(Array)
  parentGroupAttributeSpecs!: MyjbiGroupDetail['groupUserAttributeSpecs'];
  @Prop() allowedEmailDomains!: string[];
  @Prop() groupTypeName!: string;
  @Prop() groupExceptions!: ResourceExceptions;

  isDownloading = false;

  @Action('admin/exportCsvGroupMembersList')
  public exportCsvGroupMembersList!: (
    params: ExportGroupMembersCsvPayload
  ) => Promise<void>;

  @Action('admin/getMembers')
  getMembers!: (params: {
    groupId: number;
    options: FilteredGroupMemberParamsPayload;
  }) => Promise<Pagination<MyjbiGroupMember>>;

  @State((state: RootState) => state.admin.members)
  public membersDetails!: Pagination<MyjbiGroupMember>;

  public perPage = 50;
  public page = 1;
  public filteredMemberParams = this.getInitFormParams();

  public sortColumn: string = 'email';
  public sortOrder: 'ASC' | 'DESC' = 'ASC';

  public isUserAllowed(
    action: PermissionsMatrixActionsEnum,
    module: string,
    skipImplicitCheck?: boolean
  ): boolean {
    const instance = EntityTypes.GROUP + '_' + this.groupId;
    return isUserAllowed(
      action,
      module,
      EntityTypes.GROUP,
      this.groupTypeName,
      this.groupId,
      this.groupExceptions,
      skipImplicitCheck
    );
  }

  get MemberList() {
    return MemberList;
  }

  get groupId() {
    return +this.$route.params.groupId;
  }

  get allMembers() {
    if (this.membersDetails && this.membersDetails) {
      return this.membersDetails.items;
    }
  }

  public resetFilter() {
    this.filteredMemberParams = this.getInitFormParams();
  }

  public async handlePaginator({
    perPage,
    page
  }: {
    perPage: number;
    page: number;
  }) {
    this.filteredMemberParams = this.getInitFormParams();
    const queryParams = this.$route.query;
    const queryFilter = this.filteredMemberParams;
    if (queryParams && Object.keys(queryParams).length) {
      Object.keys(queryParams).forEach((each) => {
        if (Object.keys(this.filteredMemberParams).includes(each)) {
          this.filteredMemberParams[each] = queryParams[each];
          return (queryFilter[each] = queryParams[each]);
        }
      });
    }

    this.perPage = perPage || +this.$route.query.limit;
    this.page = page || +this.$route.query.page;

    await this.getMembers({
      groupId: this.groupId,
      options: {
        ...this.filteredMemberParams,
        limit: this.perPage,
        page: this.page,
        sortColumn: (this.$route.query.sortColumn as string) || this.sortColumn,
        sortOrder:
          (this.$route.query.sortOrder as 'ASC' | 'DESC') || this.sortOrder
      }
    });
    this.$router.push({
      path: this.$route.path,
      query: {
        ...this.filteredMemberParams,
        limit: this.perPage.toString(),
        page: this.page.toString(),
        sortColumn: (this.$route.query.sortColumn as string) || this.sortColumn,
        sortOrder:
          (this.$route.query.sortOrder as 'ASC' | 'DESC') || this.sortOrder
      }
    });
  }

  public async handleSort(data: any) {
    this.sortColumn = data.sortColumn;
    this.sortOrder = data.sortOrder;

    const params = {
      ...this.filteredMemberParams,
      limit: +this.$route.query.limit || this.perPage,
      page: +this.$route.query.page || this.page,
      sortColumn: this.sortColumn,
      sortOrder: this.sortOrder
    };

    await this.getMembers({ groupId: this.groupId, options: params });
    this.$router.push({
      path: this.$route.path,
      query: {
        ...this.filteredMemberParams,
        limit: (this.$route.query.limit as string) || this.perPage.toString(),
        page: (this.$route.query.page as string) || this.page.toString(),
        sortColumn: this.sortColumn,
        sortOrder: this.sortOrder
      }
    });
  }

  public async handleFilter() {
    this.perPage = +this.$route.query.limit;
    const queryParams = this.$route.query;
    const queryFilter = this.getInitFormParams();
    Object.keys(queryParams).forEach((each) => {
      if (Object.keys(this.filteredMemberParams).includes(each)) {
        return (queryFilter[each] = queryParams[each]);
      }
    });
    if (
      JSON.stringify(queryFilter) !== JSON.stringify(this.filteredMemberParams)
    ) {
      this.page = 1;
      await this.getMembers({
        groupId: this.groupId,
        options: {
          ...this.filteredMemberParams,
          limit: +this.$route.query.limit || this.perPage,
          page: 1,
          sortColumn:
            (this.$route.query.sortColumn as string) || this.sortColumn,
          sortOrder:
            (this.$route.query.sortOrder as 'ASC' | 'DESC') || this.sortOrder
        }
      });
      this.$router.push({
        path: this.$route.path,
        query: {
          ...this.filteredMemberParams,
          limit: (this.$route.query.limit as string) || this.perPage.toString(),
          page: (this.$route.query.page as string) || this.page.toString(),
          sortColumn:
            (this.$route.query.sortColumn as string) || this.sortColumn,
          sortOrder:
            (this.$route.query.sortOrder as 'ASC' | 'DESC') || this.sortOrder
        }
      });
    }
  }

  public async handleResetQuery() {
    await this.getMembers({
      groupId: this.groupId,
      options: {
        ...this.filteredMemberParams,
        limit: +this.$route.query.limit || this.perPage,
        page: +this.$route.query.page || this.page,
        sortColumn: (this.$route.query.sortColumn as string) || this.sortColumn,
        sortOrder:
          (this.$route.query.sortOrder as 'ASC' | 'DESC') || this.sortOrder
      }
    });
  }

  public mounted() {
    this.filteredMemberParams = this.getInitFormParams();
    const queryParams = this.$route.query;
    let queryFilter = this.filteredMemberParams;
    if (queryParams && Object.keys(queryParams).length) {
      Object.keys(queryParams).forEach((each) => {
        if (Object.keys(this.filteredMemberParams).includes(each)) {
          this.filteredMemberParams[each] = queryParams[each];
          return (queryFilter[each] = queryParams[each]);
        }
      });
    } else {
      queryFilter = this.filteredMemberParams;
    }

    this.$router.push({
      path: this.$route.path,
      query: {
        ...queryFilter,
        limit: this.perPage.toString(),
        page: (this.$route.query.page as string) || this.page.toString(),
        sortColumn: (this.$route.query.sortColumn as string) || this.sortColumn,
        sortOrder:
          (this.$route.query.sortOrder as 'ASC' | 'DESC') || this.sortOrder
      }
    });
    this.perPage = +this.$route.query.limit || this.perPage;
    this.page = +this.$route.query.page || this.page;

    this.handleResetQuery();
  }
  get groupDetail() {
    return (this.$store.state as RootState).admin.groupDetail;
  }

  get attributeOrder() {
    return (this.$store.state as RootState).admin.groupAttributeOrder;
  }

  public async handleCSVDownload() {
    this.isDownloading = true;

    if (!this.groupDetail) {
      this.isDownloading = false;
      return;
    }

    const result: ExportGroupMembersCsvPayload = {
      id: this.groupDetail?.id,
      parentsName: this.groupDetail?.parentsName,
      selectedAttributes: this.attributeSpecs?.map((eachAttr) => {
        return eachAttr.groupUserAttribute.slug;
      })
    };

    await this.exportCsvGroupMembersList(result);
    this.isDownloading = false;
  }

  get addMemberDropdownOptions() {
    return [
      this.parentGroupMembersWhoDoNotExistInCurrentGroup?.length &&
      this.isUserAllowed(
        PermissionsMatrixActionsEnum.UPDATE,
        'group_administration-groups-update_groups-update_members-create_members-create_existing_users',
        true
      )
        ? {
            text: 'From parent group',
            handler: this.handleAddFromParent.bind(this)
          }
        : undefined,

      this.isUserAllowed(
        PermissionsMatrixActionsEnum.CREATE,
        'group_administration-groups-update_groups-update_members-create_members-create_existing_users',
        true
      )
        ? {
            text: 'Add Existing MyJBI Member',
            handler: this.handleAddExistingMember.bind(this)
          }
        : undefined,
      this.isUserAllowed(
        PermissionsMatrixActionsEnum.CREATE,
        'group_administration-groups-update_groups-update_members-create_members-create_new_members',
        true
      )
        ? {
            text: 'Multiple members',
            handler: this.handleAddMultipleMembers.bind(this)
          }
        : undefined,
      this.isUserAllowed(
        PermissionsMatrixActionsEnum.CREATE,
        'group_administration-groups-update_groups-update_members-create_members-create_new_members',
        true
      )
        ? {
            text: 'Single member',
            handler: this.handleAddSingleMember.bind(this)
          }
        : undefined
    ].filter(Boolean);
  }

  get parentGroupMembersWhoDoNotExistInCurrentGroup() {
    if (this.membersDetails) {
      return differenceBy(
        this.parentGroupMembers,
        this.membersDetails.items,
        (member) => member.attributes?.email
      );
    }
  }

  /* getting all user attributes
   *  except user-profile-email-verified-1001, user-profile-user-status-1002, user-profile-username-1003
   *  which have default id 0 */
  get userAttributes() {
    return this.attributeSpecs?.filter(
      (attribute) => attribute.groupUserAttribute.id
    );
  }

  getInitFormParams(): any {
    return fromPairs(
      this.attributeSpecs?.map((attribute) => [
        attribute?.groupUserAttribute?.slug,
        ''
      ])
    );
  }

  handleAddExistingMember() {
    this.$buefy.modal.open({
      parent: this,
      component: AddMembersFromListModal,
      hasModalCard: true,
      trapFocus: true,
      canCancel: false,
      props: {
        newGroup: false,
        userAttributes: this.userAttributes,
        existingMembers: this.membersDetails?.items.map(
          (member) => member.attributes
        ),
        showNotify: true,
        checkForMissingAttributeValue: true,
        allowedEmailDomains: this.allowedEmailDomains
      },
      events: {
        add: (payload: AddExistingMemberPayload) => {
          const { data, notify } = payload;
          this.$emit('addExistingMembers', { values: data, notify });
        }
      }
    });
  }

  handleAddSingleMember() {
    this.$buefy.modal.open({
      parent: this,
      component: SingleMemberModal,
      hasModalCard: true,
      trapFocus: true,
      canCancel: false,
      props: {
        isProfilePage: false,
        attributeSpecs: this.userAttributes,
        allowedEmailDomains: this.allowedEmailDomains,
        groupId: this.groupId,
        groupTypeName: this.groupTypeName,
        groupExceptions: this.groupExceptions
      },
      events: {
        submit: ({
          values,
          notify
        }: {
          values: MemberObject;
          notify: boolean;
        }) => {
          this.$emit('addMember', {
            values,
            notify
          });
        }
      }
    });
  }

  handleAddMultipleMembers() {
    this.$buefy.modal.open({
      parent: this,
      component: AddMultipleMembersModal,
      hasModalCard: true,
      trapFocus: true,
      canCancel: false,
      props: {
        attributeSpecs: this.userAttributes,
        allowedEmailDomains: this.allowedEmailDomains
      },
      events: {
        submit: ({
          values,
          notify
        }: {
          values: MemberObject[];
          notify: boolean;
        }) => {
          this.$emit('addMultipleMembers', { values, notify });
        }
      }
    });
  }

  handleAddFromParent() {
    this.$buefy.modal.open({
      parent: this,
      component: AddFromParentModal,
      hasModalCard: true,
      trapFocus: true,
      canCancel: false,
      props: {
        parentGroupMembers: this.parentGroupMembersWhoDoNotExistInCurrentGroup,
        parentGroupAttributeSpecs: this.parentGroupAttributeSpecs
      },
      events: {
        submit: ({
          values,
          notify
        }: {
          values: MemberObject[];
          notify: boolean;
        }) => {
          this.$emit('addFromParent', { values, notify });
        }
      }
    });
  }

  @Watch('isDownloading')
  public handleDownloadMembersList() {
    Toast.open({
      queue: true,
      type: 'is-dark',
      position: 'is-top',
      message: `Downloading members list...`
    });
  }
}
