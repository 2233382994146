var render = function () {
var _obj, _obj$1;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"frag",rawName:"v-frag"}]},[_c('tr',{class:( _obj = {}, _obj['row'] = true, _obj['rowLevel1'] = _vm.level === 1, _obj['rowLevel2'] = _vm.level === 2, _obj['rowLevel3'] = _vm.level === 3, _obj['rowLevel4'] = _vm.level === 4, _obj )},[_c('td',[_c('i',{staticClass:"mdi",class:( _obj$1 = {}, _obj$1['chevron'] = true, _obj$1['is-invisible'] =  _vm.noSubGroup, _obj$1['mdi-chevron-right'] = !_vm.expanded, _obj$1['mdi-chevron-down'] = _vm.expanded, _obj$1 ),on:{"click":function($event){_vm.expanded = !_vm.expanded}}}),(_vm.group.userHasAccess)?_c('router-link',{staticClass:"link",attrs:{"to":{
          name: 'admin-view-group',
          params: {
            groupId: _vm.group.id
          }
        }}},[_vm._v(" "+_vm._s(_vm.group.name)+" ")]):_c('span',{staticClass:"no-access"},[_vm._v(" "+_vm._s(_vm.group.name)+" ")]),(
          _vm._get(_vm.group, 'types.name', '') === _vm.MyjbiGroupType.WoltersKluwerUsers
        )?_c('span',{staticClass:"tag"},[_vm._v(" WK ")]):_vm._e(),(_vm.group.protectedTypes && _vm.group.protectedTypes.length > 0)?_c('span',[_vm._v(" "),_c('i',{staticClass:"fa fa-lock"})]):_vm._e()],1),_c('td',[_vm._v(_vm._s(_vm.group.types ? _vm.group.types.name : ''))]),_c('td',{attrs:{"align":"right"}},[_vm._v(" "+_vm._s(_vm.group.memberCount)+" ")])]),(_vm.expanded)?[_vm._l((_vm.descendants),function(descendant){return [_c('GroupRow',{key:descendant.id,attrs:{"group":descendant,"getChildren":_vm.getChildren,"level":_vm.level + 1,"expandGroupFilter":_vm.expandGroupFilter}})]})]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }