import { pluck } from 'rxjs/operators';

export const pluckNewValue = <T>() =>
  pluck<{ newValue: T; oldValue: T }, 'newValue'>('newValue');

export const pluckSuccess = () =>
  pluck<{ success: boolean }, 'success'>('success');

export const pluckError = () =>
  pluck<{ error: Error | null }, 'error'>('error');
